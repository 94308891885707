const riot = require('riot');

riot.tag2('modal-tags', '<div class="p16 bg-white rounded-8" ref="modal"> <div class="f fbw fm mb16"> <h2 class="fs14">{opts.currency.data.name_en} {opts.currency.data.name_ja} のタグ一覧</h2> <button class="icon-close fs20 p8 text-primary" onclick="{close}"></button> </div> <div class="row w-full"> <div class="mr8" ref="currency_tag" data-is="atom-tag-currency" each="{item in opts.categories}" item="{item}"></div> </div> </div>', 'modal-tags,[data-is="modal-tags"]{background-color:rgba(0,0,0,0.5)} modal-tags [ref=\'modal\'],[data-is="modal-tags"] [ref=\'modal\']{background-color:rgba(255,255,255,0.9);max-width:640px;width:100%}', 'class="f fh px12" spat-animation="push"', function(opts) {
    this.on('mount', () => {
      this.sections = app.utils.currencySingle.getLinkSections(opts.currency);

      var currency_tags = Array.isArray(this.refs.currency_tag) ? this.refs.currency_tag : [this.refs.currency_tag];
      currency_tags.forEach(item => {
        item.on('click', () => {
          this.close();
        });
      });
    });
});