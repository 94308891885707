const riot = require('riot');

riot.tag2('atom-input-date-range', '<label> <div class="fs12">{opts.option.label}</div> <input class="s-full text-center" ref="datetimepicker" riot-value="{opts.riotValue}" type="text" placeholder="{opts.placeholder}" oninput="{onInput}"> </label>', '', '', function(opts) {
    this.on('mount', () => {
      if (spat.isNode) return;

      this.picker = new Litepicker({
        element: this.refs.datetimepicker,
        singleMode: false,
        format: 'YYYY年M月D日',
        setup: (picker) => {
          picker.on('selected', (start, end) => {
            this.parent.trigger('setRange', ({start, end}));
          });
        },
      });
      this.update();
    });

    this.onInput = () => {

      if(!this.refs.datetimepicker.value) {
        this.picker.clearSelection();
        this.parent.trigger('setRange', null);
      }
    };

    this.getValue = () => {
      var [min, _, max] = this.refs.datetimepicker.value.split(' ');
      return [min, max];
    };

    this.clear = () => {
      this.refs.datetimepicker.value = '';
      this.update();
    };
});