import utils from './utils'
import store from './store'
import api from './api'
import media_api from './media_api'

var app = {
  store,
  utils,
  api,
  media_api,
};

export default app;