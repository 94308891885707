const riot = require('riot');

riot.tag2('module-ranking', '<div class="w-full px12 pt18 pb5 bg-white rounded-8 box-shadow-main"> <h2 class="lh15 bold fs18 mb12">仮想通貨ランキング</h2> <div class="w-full row"> <div class="col6 mb4" each="{content in ranking_contents}"> <div class="border-bottom border-base_weak bg-cell_title fs12 p8">{content.label}</div> <div class="f fm fbw border-bottom border-base_weak fs12 py7 pr8" each="{rank in content.ranking}"> <div class="f fm mr8"> <div class="bold text-base_pale mr10">{rank.rank}</div> <div class="flex-fixed s16 mr4" if="{content.showImage}"><img class="s-full object-dit-contain" riot-src="{app.utils.getImgixImageUrl(rank.currency.data.image.url, 16, 16)}" loading="lazy" alt="{rank.currency.data.symbol}"></div><a class="text-primary" href="/cryptocurrency/{rank.currency.data.slug}/">{rank.currency.data.symbol}</a> </div> <div class="f flex-fixed w63"> <div if="{content.showValue}"> <div class="f fh text-{rank.currency.data[getRankingField(content)].toFixed(2) &lt; 0 ? \'minus\' : \'plus\'}"> <div class="h-full fs10 mr4 icon-{rank.currency.data[getRankingField(content)].toFixed(2) &lt; 0 ? \'triangle_down\' : \'triangle_up\'}"></div> <div>{app.utils.localizeFixed(Math.abs(rank.currency.data[getRankingField(content)].toFixed(2)))}%</div> </div> </div> </div> </div><a class="f fr text-primary lh15 py5" href="/cryptocurrency?sort={content.id}"> <span class="fs12 mr8">もっと見る</span><i class="icon-arrow_right"></i></a> </div> </div> </div>', 'module-ranking,[data-is="module-ranking"]{display:block}', '', function(opts) {
    this.preload = async ({req, res}) => {
      var ranking_contents = [
        {id: 'increase', label: '値上がり率', field: 'percent_change_24h', order: 'desc', showValue: true},
        {id: 'decrease', label: '値下がり率', field: 'percent_change_24h', order: 'asc', showValue: true},
        {id: 'volume', label: '活況銘柄', field: 'volume_24h', order: 'desc', showImage: true},
        {id: 'market_cap', label: '時価総額', field: 'market_cap', order: 'desc', showImage: true},
      ];

      var promises = ranking_contents.map(async content => {

        const LIMIT = 3;
        var field = this.getRankingField(content);
        var currencies = await app.store.currencies.cryptoIndex({kind: field, order: content.order, limit: LIMIT} );
        content.ranking = currencies.map((currency, index) => {
          return {
            rank: index +1,
            currency: currency,
          };
        });
      });

      await Promise.all(promises);

      return {
        ranking_contents
      };
    };

    this.on('update', () => {
      this.currency = app.utils.selectedCurrency.getId();
    });

    this.getRankingField = (content) => {
      return app.utils.selectedCurrency.getId().toLocaleLowerCase() + "_" + content.field;
    };
});