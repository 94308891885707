const riot = require('riot');

riot.tag2('module-exchange-news', '<div class="bg-white box-shadow-main rounded-8 pt18 px24 pb24 mx0 s-px16"> <h3 class="bold fs18 lh15 mb11">{opts.title} の最新ニュース</h3> <div class="border rounded-8 px24 mb16 s-px16"> <div class="border-bottom-not-last pt18 pb16 fs16 s-py16" each="{feed in app.utils.exchangeSingle.getShowItems(feeds, limit, opts.isdetail)}"><a class="f fm text-primary bold fs18 lh15 mb2" href="{feed.data.url}" target="_blank" rel="noopener nofollow">{feed.data.title}</a> <div class="fs16 lh17 mb8">{feed.data.description}</div> <div class="f fm fbw"> <div class="fs14 text-base_pale">{feed.data.site_name}</div> <time class="fs12 text-base_pale">{app.utils.formatDate.minutes(feed.data.published_at)}</time> </div> </div> </div> <button class="w-full f fh text-primary" if="{last &amp;&amp; opts.isdetail}" onclick="{onReadMore}"> <div class="fs12 mr8">さらにもっと見る</div><i class="icon-arrow_down fs8"></i> </button> <div class="f fr text-primary pb8" if="{!opts.isdetail}"><a class="f fm" href="/exchange/{opts.exchange.data.slug}/feed"> <div class="fs12 mr8">{opts.exchange.data.name_en} ({opts.exchange.data.name_ja}) の最新ニュースをもっと見る</div><i class="icon-arrow_right fs8"></i></a></div> </div>', '', '', function(opts) {
    this.preload = async () => {
      this.limit = 3;
      var slug = this.opts.exchange.data.slug;
      var feeds = await app.store.exchange_feeds.indexByExchange({slug, limit: this.limit});
      var last = feeds.length ? feeds[feeds.length - 1].doc : '';

      return {
        feeds,
        last,
      };
    };

    this.onReadMore = async () => {
      var slug = this.opts.exchange.data.slug;
      var feeds = await app.store.exchange_feeds.indexByExchange({slug, limit: 10, last: this.last});

      if (!feeds.length) {
        delete this.last;
      }
      else {
        this.last = feeds[feeds.length - 1].doc;
        this.feeds = [...this.feeds, ...feeds];
      }
      this.update();
    };
});