const riot = require('riot');

riot.tag2('modal-nav', '<div class="s-full" ref="modal" onclick="{onStartClosing}"> <header data-is="module-header" opened="{true}"></header> <div class="body overflow-y-scroll scrollbar-none max-height-80vh w-full rounded-bottom-16 bg-white lh19 {isOpenNav ? &quot;anim-open&quot;: &quot;anim-close&quot;}" ref="body" onclick="event.stopPropagation();" riot-style="--body-height: {refs.body.scrollHeight}px;" onanimationend="{onCloseTransition}"> <div class="pt16 px16"> <div each="{section, index in opts.sections}" data-is="item-nav_" section="{section}"> <div data-is="item-nav_" section="{section}"></div> </div> </div> <div class="text-primary text-center pb16 cursor-pointer" onclick="{onStartClosing}">閉じる</div> </div> </div>', 'modal-nav,[data-is="modal-nav"]{background-color:rgba(38,49,55,0.6)} modal-nav .max-height-80vh,[data-is="modal-nav"] .max-height-80vh{max-height:80vh} modal-nav .body,[data-is="modal-nav"] .body{height:0} modal-nav .anim-open,[data-is="modal-nav"] .anim-open{animation:256ms anim-open;animation-fill-mode:forwards} modal-nav .anim-close,[data-is="modal-nav"] .anim-close{height:var(--body-height);animation:256ms anim-close;animation-fill-mode:forwards}@keyframes anim-close{ 0%{height:var(--body-height)} 100%{height:0}}@keyframes anim-open{ 0%{height:0} 99.9%{height:var(--body-height)} 100%{height:auto}}', 'spat-animation=""', function(opts) {
    this.isOpenNav = false;

    this.on('mount', () => {

      requestAnimationFrame(() => {

        this.update();

        this.isOpenNav = true;
        this.update();
      });
    });

    this.onStartClosing = () => {
      this.isOpenNav = false;
    };

    this.onCloseTransition = () => {
      if (this.isOpenNav === false) {
        this.close();
      }
    };

});
riot.tag2('item-nav_', '<div class="f fm fbw cursor-pointer py8" onclick="{onToggleOpenSub}"> <div class="fs16 lh17 bold">{opts.section.label}</div><i class="icon-arrow-down fs16 transition {rotate-180 : isOpen}"></i> </div> <div class="overflow-hidden transition" ref="topic_height" riot-style="max-height:{isOpen ? refs[&quot;topic_height&quot;].scrollHeight : &quot;0&quot;}px"><a href="{opts.section.link}" if="{opts.section.title}"> <div class="text-primary bold lh15 py10">{opts.section.title}</div></a><a class="f fm text-primary pl16 py10 mb0-last" href="{item.url}" each="{item in opts.section.items}"> <div class="fs14 lh16">{item.label}</div></a></div>', '', '', function(opts) {

    this.onToggleOpenSub = (e) => {
      e.stopPropagation();
      this.isOpen = !this.isOpen;
      this.update();
    };
});