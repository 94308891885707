const riot = require('riot');

riot.tag2('module-details', '<div class="bg-white rounded-8 box-shadow-main pt18 px24 pb24 s-px16"> <h2 class="fs18 lh15 mb11">{opts.currency.data.name_ja} {opts.currency.data.name_en} {opts.currency.data.symbol} の詳細情報</h2> <div each="{item in opts.items}" if="{item.data.length}"> <h3 class="fs16 lh15 mb8">{item.name}</h3> <div class="w-full box-shadow-main mb15 fs12 rounded-8 border"> <div class="row w-full"> <div class="col6 f fh flex-fixed lh15 s-col12 s-border-bottom-not-last s-flex-between {getBorderStyle(item.data, index)}" each="{data, index in item.data}"> <div class="w50per text-center text-base_pale f fh p10 s-w-auto s-text-left"> <div>{data.name}</div> <div class="ml2" data-is="atom-word-description" if="{data.help}" description="{data.help}"></div> </div> <div class="w50per text-center flex-fixed p10 py4 word-break-all s-w138"><span class="text-{getTextClass(data)}" if="{!isLink(data.value)}">{data.value}</span><a class="text-primary" if="{isLink(data.value)}" href="{data.value}" target="_blank" rel="noopener nofollow">{data.value}</a></div> </div> </div> </div> </div> <div class="w-full f fr" if="{opts.canlink}"><a class="text-primary f fm" href="/cryptocurrency/{opts.currency.data.slug}/detail"> <div class="fs12 lh15 text-right mr8">{opts.currency.data.name_ja} ({opts.currency.data.symbol}) の詳細情報をもっと見る</div><i class="icon-arrow_right pt1 fs8"></i></a></div> </div>', '', '', function(opts) {
    this.getBorderStyle = (data, index) => {

      var border_last_index = (data.length - 1) % 2 === 0 ? data.length - 2 : data.length - 3;
      return index <= border_last_index ? 'border-bottom' : '';
    };

    this.isLink = (value) => {
      return /^https?:\/\/[\w\/:%#\$&\?~\.=\+\-\_\@]+/.test(value);
    };

    this.getTextClass = (data) => {
      if (!/▲|▼/.test(data.value)) return;
      return data.value_num > 0 ? "plus" : "minus";
    };
});