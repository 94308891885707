const riot = require('riot');

riot.tag2('page-cryptocurrency-category', '<div class="f fclm"> <header data-is="module-header"></header> <div class="container-1048 h-full"> <div class="px12" data-is="module-breadcrumbs" ref="breadcrumbs"></div> <div class="px12 mb12 s-mb16" data-is="module-description" ref="description" title="仮想通貨カテゴリ一覧" description="Defi、NFTなど主要仮想通貨(暗号資産)のカテゴリ一覧です。"></div> <div class="px12 mb24" data-is="module-daily-info" item="{daily_info}"></div> <div class="px12 s-pl12 s-pr0 mb24" data-is="module-category-table"></div> <div class="px12 mb24" data-is="module-markets" items="{exchanges}"></div> <div class="mb24 s-mb56 px12" data-is="module-contact"></div> <div class="mb24 s-mb56 px12" data-is="module-register-email"></div> <div class="mb72" data-is="item-api-reference"></div> </div> <footer class="mta" data-is="module-footer"></footer> </div> <div data-is="module-btn-page-top"></div>', '', '', function(opts) {
    this.preload = async({req, res}) => {
      var promises = [
        app.store.daily_information.get(),
        app.store.exchanges.index({}),
      ];
      var [daily_info, exchanges] = await Promise.all(promises);

      return {
        daily_info,
        exchanges,
      };
    };

    this.head = () => {
      return {
        title: `暗号資産(仮想通貨) カテゴリ一覧 | ${spat.config.site_name}`,
        description: '暗号資産(仮想通貨)のカテゴリ一覧 - Defi、NFT等のカテゴリ一覧です。どの暗号資産カテゴリが注目されているのかが分かります。',
        keywords: '暗号資産,仮想通貨,暗号通貨,カテゴリ,Defi,NFT,日本国内ホワイトリスト,一覧,ランキング',
      };
    };
});