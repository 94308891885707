const riot = require('riot');

riot.tag2('module-chart', '<svg class="w-full" ref="graph"></svg> <div class="relative f fm"> <div class="fs16 mr8 mb12 cursor-pointer" each="{term in terms}"> <button class="p8 {&quot;text-primary&quot;: term === currentTerm}" onclick="{changeTerm}">{term.label}</button> <div class="h2 w-full {term === currentTerm ? &quot;bg-primary&quot; : &quot;bg-transparent&quot;}"></div> </div> </div>', 'module-chart,[data-is="module-chart"]{display:block}', '', function(opts) {
    this.width = 600;
    this.height = 300;
    this.beforeViewPair = '';

    this.isEnable1D = false;
    this.isEnable1W = true;
    this.isEnable1M = false;
    this.isEnable1Y = false;

    this.terms = [
      { label: '1日', chart: '5min', open_at: dayjs().subtract(1, 'day').toDate().getTime() },
      { label: '1週間', chart: '1hour', open_at: dayjs().subtract(7, 'day').toDate().getTime() },
      { label: '1ヶ月', chart: '1hour', open_at: dayjs().subtract(1, 'month').toDate().getTime() },
      { label: '1年', chart: '1day', open_at: dayjs().subtract(1, 'year').toDate().getTime() },
    ];
    this.currentTerm = this.terms[1];

    this.on('mount', async () => {
      this.loaded = false;
    });

    this.createChart = (width, height, data) => {

      var chart = new Chart({
        element: this.refs.graph,
        width: width,
        height: height,
        max: 220,
        min: 0,
        data: data,
      });

      chart.on('update', () => {
        this.trigger('chartUpdate');
      });

      return chart;
    };

    this.setPair = async (pair) => {

      const MAX_DATA_NUM = 1000;

      this.beforeViewPair = pair;
      var data = await firebase.firestore().collection(`/pairs/${pair}/candlestick_${this.currentTerm.chart}`).where('open_at', '>', this.currentTerm.open_at).get();
      data = data.docs.map(d => {
        return {
          date: d.data().open_at,
          value: d.data().close,
        }
      });

      var useData = Array(MAX_DATA_NUM);
      var firstData = data[0];

      for (var i = useData.length-1, count = 0; i >= 0; --i) {
        var temp = data[data.length - 1 - count];
        ++count;
        if (!!temp) {
          useData[i] = temp;
        }

        else {
          useData[i] = {
            date: firstData.date,
            value: firstData.value,
          };
        }
      }

      var width = this.refs.graph.clientWidth;
      var height = width * 0.6;
      this.refs.graph.setAttribute('viewBox', `0 0 ${width} ${height}`);
      this.loaded = true;

      if (!this.chart) {

        this.chart = this.createChart(width, height, useData);
        this.chart.initText();
        riot.update();
        return;
      }

      this.chart.initText();
      this.chart.init({
        element: this.refs.graph,
        width: width,
        height: height,
        max: 220,
        min: 0,
        data: useData,
      });
      this.chart.reRender();
    };

    this.changeTerm = (e) => {
      let term = e.item.term;
      this.currentTerm = term;

      this.setPair(this.beforeViewPair);

      this.update();
    };
});