const riot = require('riot');

riot.tag2('module-cryptocurrency-news', '<div class="bg-white box-shadow-main rounded-8 pt18 px24 pb24 mx0 s-px16"> <h2 class="bold fs18 lh15 mb11">{opts.currency.data.name_ja} {opts.currency.data.name_en} {opts.currency.data.symbol} の最新ニュース</h2> <div class="border rounded-8 px24 mb16 s-px16"> <div class="border-bottom-not-last pt18 pb16 fs16 s-py16" each="{feed in app.utils.currencySingle.getShowItems(feeds, limit, opts.isdetail)}"> <div class="f ft"><img class="s-show flex-fixed block w32 mt8 mr4" riot-src="/images/news/{feed.data.sentiment}.svg" loading="lazy" alt=""><a class="f fm text-primary bold fs18 lh15 mb2" href="{feed.data.url}" rel="noopener nofollow" target="_blank">{feed.data.title}</a></div> <div class="fs16 lh17 mb8">{feed.data.description}</div> <div class="f fm fbw"> <div class="f fh"><img class="s-hide block w32 mr8" riot-src="/images/news/{feed.data.sentiment}.svg" loading="lazy" alt=""> <div class="fs14 text-base_pale">{feed.data.site_name}</div> </div> <time class="fs12 text-base_pale">{app.utils.formatDate.minutes(feed.data.published_at)}</time> </div> </div> </div> <button class="w-full f fh text-primary" if="{last &amp;&amp; opts.isdetail}" onclick="{onReadMore}"> <div class="fs12 mr8">さらにもっと見る</div><i class="icon-arrow_down fs8"></i> </button> <div class="f fr text-primary pb8" if="{!opts.isdetail}"><a class="f fm" href="/cryptocurrency/{opts.currency.data.slug}/news"> <div class="fs12 mr8">{opts.currency.data.name_ja} ({opts.currency.data.symbol}) のニュースをもっと見る</div><i class="icon-arrow_right fs8"></i></a></div> </div>', '', '', function(opts) {
    this.preload = async () => {

      this.limit = 6;
      var symbol = this.opts.currency.data.symbol;
      var feeds = await app.store.cryptocurrency_feeds.indexBySymbol({symbol, limit: this.limit});
      var last = feeds.length ? feeds[feeds.length - 1].doc : '';
      return {
        feeds,
        last,
      };
    };
    this.onReadMore = async () => {
      var symbol = this.opts.currency.data.symbol;
      var feeds = await app.store.cryptocurrency_feeds.indexBySymbol({symbol, limit: 10, last: this.last});

      if (!feeds.length) {
        delete this.last;
      }
      else {
        this.last = feeds[feeds.length - 1].doc;
        this.feeds = [...this.feeds, ...feeds];
      }
      this.update();
    };
});