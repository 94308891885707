const riot = require('riot');

riot.tag2('page-exchange-list', '<div class="f fclm" show="{exchangesStore.firstLoaded}"> <header data-is="module-header"></header> <main class="min-100vh"> <div class="container-1048"> <div class="px12 mb16" data-is="module-breadcrumbs" ref="breadcrumbs"></div> <div class="px12 mb16" data-is="module-description" title="仮想通貨取引所 一覧" description="仮想通貨の取引・購入ができる取引所の一覧です。日本国内の取引所だけでなく、海外取引所も含めて一覧化しています。"></div> <div class="bg-white px24 pb24 pt18 rounded-8 box-shadow-primary mx12 mb24 s-p16"> <h2 class="bold fs18 lh15 mb16">取引所人気ランキング一覧</h2> <div class="mb16 mxa" each="{item in exchangesStore.items}" data-is="item-exchange-card" item="{item}"></div> <div class="f fh"> <button class="f fm cursor-pointer p8 text-primary" onclick="{onReadMoreExchange}" if="{exchangesStore.items.length &amp;&amp; exchangesStore.hasMore}"> <div class="fs12 mr9">さらにもっと見る</div><i class="icon-arrow_down fs5"></i> </button> </div> </div> <div class="bg-white px24 pt18 pb24 rounded-8 box-shadow-primary mx12 mb72 s-p16"> <h2 class="fs18 text-base bold lh15 mb16">暗号資産取引所 の評判・口コミ・レビュー</h2> <div class="mb16" each="{item in exchangeReviewsStore.items}" data-is="item-exchange-review" item="{item.review}" exchange="{item.exchange}"></div> <div class="f fh"> <button class="f fm cursor-pointer p8 text-primary" onclick="{onReadMoreReview}" if="{exchangeReviewsStore.items.length &amp;&amp; exchangeReviewsStore.hasMore}"> <div class="fs12 mr9">さらにもっと見る</div><i class="icon-arrow_down fs5"></i> </button> </div> </div> </div> </main> <footer class="mta" data-is="module-footer"></footer> </div> <div data-is="module-btn-page-top"></div>', '', '', function(opts) {

    this.exchangesStore = {
      firstLoaded: false,
      items: [],
      init: function() {
        this.items = [];
        this.hasMore = false;
        this.firstLoaded = false;
        return this;
      },
      load: async function({ limit = 10 } = {}) {
        var last = this.items[this.items.length - 1];
        var items = await app.store.exchanges.index({limit, last: last && last.doc});
        this.hasMore = items.length && items.length === limit;
        this.items.push(...items);
        this.firstLoaded = true;
      },
    };

    this.exchangeReviewsStore = {
      items: [],
      init: function() {
        this.items = [];
        this.hasMore = false;
        return this;
      },
      load: async function({ limit = 10 } = {}) {
        var last = this.items[this.items.length - 1];
        var items = await app.store.exchange_reviews.index({limit, last: last && last.doc});
        this.hasMore = items.length && items.length === limit;

        const promises = items.map(async item => {
          const exchange = await app.store.exchanges.getById(item.data.exchange_id);
          return {
            exchange,
            review: item,
          };
        });
        items = await Promise.all(promises);
        this.items.push(...items);
      },
    };

    this.preload = async({req, res}) => {
      const REVIEW_LIMIT = 3;
      const EXCHANGE_LIMIT = 10;
      var promises = [
        this.exchangesStore.init().load({limit: EXCHANGE_LIMIT}),
        this.exchangeReviewsStore.init().load({limit: REVIEW_LIMIT}),
      ];
      await Promise.all(promises);
      return {};
    };

    this.head = () => {
      var title = '暗号資産(仮想通貨)取引所 一覧';

      return {
        title: `${title} | ${spat.config.site_name}`,
        description: `${title} - 仮想通貨取引ができる取引所一覧です。日本国内の取引所だけでなく、海外取引所も含めて全世界の暗号資産(仮想通貨)取引所を一覧化しています。`,
        keywords: '暗号資産,仮想通貨,暗号通貨,取引所,販売所,一覧,日本,国内,海外',
      };
    };

    this.onReadMoreReview = async(e) => {
      e.preventDefault();
      await this.exchangeReviewsStore.load({limit: 10});
      this.update();
    };

    this.onReadMoreExchange = async(e) => {
      e.preventDefault();
      await this.exchangesStore.load({limit: 10});
      this.update();
    };
});