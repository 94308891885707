const riot = require('riot');

riot.tag2('atom-filter-controller', '<div class="relative w-full box-shadow-primary flex-fixed f fh rounded-8 border mr16 {isSelecting ? \'border-transparent\' : \'\'}"> <button class="w-full f fm fbw p8" onclick="{onSelect}"> <div class="fs12 mr8">{opts.riotValue.text}</div><i class="icon-arrow_down fs8"></i> </button> <div class="absolute s-full t0 l0 z2" if="{isSelecting}"> <div class="rounded-8 box-shadow-primary bg-white border"> <button class="relative f fm p8 border-bottom-not-last w-full hover-pale_dark" each="{item, i in opts.items}" onclick="{onChange}"> <div class="fs12 mr8">{item.text}</div> <div class="absolute h-full t0 r0 px8 f fh" if="{i === 0}"><i class="icon-arrow_up fs8"></i></div> </button> </div> </div> </div>', '', '', function(opts) {

    this.onSelect = () => {
      this.isSelecting = true;
      setTimeout(() => {
        document.addEventListener('click', this.closePopup);
      }, 100);
      this.update();
    };

    this.closePopup = () => {
      this.isSelecting = false;
      document.removeEventListener('click', this.closePopup);
      this.update();
    };

    this.onChange = async(e) => {
      e.preventDefault();
      this.trigger('change', e);
    };
});