const riot = require('riot');

riot.tag2('module-btn-page-top', '<div class="fixed z10 r40 b40 f fh animation-appear s-r24 s-b24" if="{isVisible}"> <button class="s48 bg-primary circle" onclick="{onScrollPageTop}"><i class="icon-arrow_up fs24 text-white"></i></button> </div>', '', '', function(opts) {
    const SHOW_THRESHOLD = 50;
    this.isVisible = false;

    this.on('mount', () => {
      if (spat.isBrowser) {

        this.checkShow();

        var checkShowDebounce = _.debounce(this.checkShow, 32);
        window.addEventListener('scroll', (e) => {

          checkShowDebounce();
        });
      }
    });

    this.checkShow = () => {
      if (this.isVisible === false && window.scrollY >= SHOW_THRESHOLD) {
        this.isVisible = true;
        this.update();
      }
      else if (this.isVisible === true && window.scrollY < SHOW_THRESHOLD) {
        this.isVisible = false;
        this.update();
      }
    };

    this.onScrollPageTop = () => {
      $('body,html').animate({
        scrollTop: 0
      }, 256);
    };
});