const riot = require('riot');

riot.tag2('atom-change-number', '<div class="f fh text-{opts.riotValue &lt; 0 ? \'minus\' : \'plus\'}"> <div class="h-full fs10 mr4 icon-{opts.riotValue &lt; 0 ? \'triangle_down\' : \'triangle_up\'}"></div> <div><span if="{symbol === \'¥\' || symbol === \'$\'}">{symbol}</span><span>{symbol === ⁗%⁗ ? app.utils.localizeFixed(Math.abs(opts.riotValue)) : Math.abs(opts.riotValue).toLocaleString()}</span><span if="{symbol !== \'¥\' &amp;&amp; symbol !== \'$\'}">{symbol}</span></div> </div>', '', '', function(opts) {
    this.on('mount', () => {
      this.syncSymbol();
    });

    this.on('mount', () => {
      this.syncSymbol();
    });

    this.on('update', () => {
      this.syncSymbol();
    });

    this.syncSymbol = () => {

      var tempSymbol = this.symbol;

      if (opts.unit && opts.unit !== 'JPY' && opts.unit !== 'USD') {
        this.symbol = opts.unit;
      }

      var currency = opts.unit || app.utils.selectedCurrency.getId();
      if (currency === 'JPY') this.symbol = '¥';
      if (currency === 'USD') this.symbol = '$';
      if (currency === 'BTC') this.symbol = 'BTC';

      if (this.symbol !== tempSymbol) this.update();
    };
});