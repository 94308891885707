import dayjs from 'dayjs';
import EventEmitter from 'events';
class Chart extends EventEmitter {
  constructor(options) {
    super();
    this.lineColor = '#4772D5';
    this.weekLineColor = '#3B70D9';

    this.data = [
      { value: 970000, date: '1988/09/16', },
      { value: 1270000, date: '1995/09/16', },
      { value: 2000000, date: '2010/09/16', },
      { value: 6500000, date: '2020/09/16', },
    ];

    this.element = d3.select(options.element);
    this.path = this.element.append('path');
    this.pathGradient = this.element.append('path');
    this.axisX = this.element.append('g');
    this.axisY = this.element.append('g');
    // カーソルと一致する縦のライン
    this.verticalLine = this.element.append('line');
    // チャートと縦ラインの交錯する部分の円
    this.verticalCircle = this.element.append('circle');
    // グラデーションの管理上、IDで記載する必要があるため重複を防ぐランダム値
    this.randomHash = Math.random().toString(32).substring(2);

    // verticalCircleの円の周りに白の円をかぶせる（グラデで対応）
    const verticalRadialGradient = this.element.append("defs")
      .append("radialGradient")
      .attr('id', `radial-gradient-circle-${this.randomHash}`);
    // チャートと縦ラインの交錯する部分の円表示
    // 円の周りに白の円をかぶせる
    verticalRadialGradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", this.lineColor)
      .attr("offset", "60%")
      .attr("stop-color", this.lineColor);
    verticalRadialGradient
      .append("stop")
      .attr("offset", "68%")
      .attr("stop-color", d3.rgb(17, 79, 201, 0.2))
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", d3.rgb(17, 79, 201, 0.2))
      ;

    this.init(options);
    this.render();

    // チャートと縦ラインの交錯する部分の情報のポップアップ
    this.verticalText = this.element.append('g');
    this.verticalText.selectAll('text')
      .data([0, 20])
      .enter()
      .append('text')
      .text('')
      .attr('dy', d => d)
    ;
    
    // 直前のポジションを保持しておく
    this.pointerBeforeY = 0;
    var pointStart = (e) => {
      this.pointerBeforeY = e.clientY || e.touches[0].clientY;;
      this.verticalLine.style('display', '');
      this.verticalText.style('display', '');
      this.verticalCircle.style('display', '');

      // もしSPの場合はstart時には挙動させる
      if (!!e.touches) {
        pointing(e);
      }
    };
    var pointEnd = (e) => {
      this.pointerBeforeY = 0;
      // もしSPの場合は消さない
      if (!!e.touches) { return; }
      this.verticalLine.style('display', 'none');
      this.verticalText.style('display', 'none');
      this.verticalCircle.style('display', 'none');
      // 最新情報での表示に戻す
      this.initText();
    };
    var pointing = (e) => {
      const rect = options.element.getBoundingClientRect();
      // 自分のカーソルをダイレクトに使用せず、次のデータ表示へ移動する際にデータ幅を考慮する
      var clientX = e.clientX || e.touches[0].clientX;
      var clientY = e.clientY || e.touches[0].clientY;
      let x = clientX - rect.left + (this.width / this.data.length / 2)|0;
      let y = clientY - rect.top;
      var deltaY = y - this.pointerBeforeY;
      this.pointerBeforeY = y;
      // 現在の座標からカーソルのある日付を取得する
      const xx = this.scaleX.invert(x);
      const date = dayjs(xx).toDate().getTime();

      // 一番近いデータを取得する
      const index = bisectDate(this.data, date, 1);
      let hit = this.data[index-1];

      // データがヒットしていたとしても、同じデータを指していたら処理は行わない
      hit = (this.hitDate !== hit.date) ? hit : false;

      // カーソルを移動するべき情報があたるまでmousemoveを行った
      // 該当するデータが存在する場合のみ縦線の移動
      if (hit) {
        // ヒットしたときのデータを保存しておく
        this.hitDate = hit.date;

        // データから座標を逆算して位置表示を行う
        this.moveVerticalLine(hit.date, hit.value);

        this.value = hit.value;
        this.date = date;
        this.emit('update');
      }

      // SPの場合のみスクロールに制限をかける（ドラッグ操作を行うため）
      // また、複数タッチしていた場合も挙動を邪魔しない
      if (!!e.touches && e.touches[1] === undefined) {
        // また、縦へのスクロール量が多い場合はスクロールを阻害しない
        var limitYJudgedScrolling = 4;
        if (Math.abs(deltaY) < limitYJudgedScrolling) {
          // スクロールしない
          if (e.cancelable) {
            e.preventDefault();
          }
        }
      }
    };

    this.element
      .on('mouseenter', pointStart)
      .on('mouseleave', pointEnd)
      .on('mousemove', pointing)
      .on('touchstart', pointStart)
      .on('touchend', pointEnd)
      .on('touchcancel', pointEnd)
      .on('touchmove', pointing)
      ;
    
    var bisectDate = d3.bisector(function(d) { return d.date; }).left;

    // 最新情報での表示
    this.initText();
  }

  // 表示する金額などの初期化（最終値/最新値に強制)
  initText() {
    const tempData = this.data[this.data.length-1];
    this.value = tempData.value;
    this.date = tempData.date;
    setTimeout(() => {
      this.emit('update');
      this.emit('mousemove');
    });
  }

  // 縦のライン、日付、円の移動
  moveVerticalLine(date, value) {
    var tempX = this.scaleX(date);
    this.verticalLine.attr('x1', tempX);
    this.verticalLine.attr('x2', tempX);
    var tempY = this.scaleY(value);
    this.verticalCircle.attr('cx', tempX);
    this.verticalCircle.attr('cy', tempY);

    // 縦線の上に日付を表示する
    const fontSize = 9;
    // 画面端にきたら見切れないように調整
    const currentDateFormatted = dayjs(date).format('YYYY/MM/DD HH:mm');
    const textHalfSize = ((currentDateFormatted.length / 2) * fontSize)|0;
    tempX = Math.max(Math.min(tempX, this.right - textHalfSize), this.left + textHalfSize);
    this.verticalText.selectAll('text')
      .data([currentDateFormatted])
      .text(d => d)
      .attr('x', tempX - textHalfSize)
      .attr('y', 12)
    ;
  }

  setAxisX(min, max, tickFormat) {
    this.scaleX = d3.scaleTime()
      .domain(d3.extent(this.data, function(d) { 
        return new Date(d.date);
      }))
      .range([this.left, this.right])
      ;
  }

  setAxisY(min, max, tickFormat) {
    if (min !== undefined) this.min = min;
    if (max !== undefined) this.max = max;

    this.scaleY = d3.scaleLinear()
      .domain([this.max, this.min])
      .range([this.top, this.bottom])
      ;

    var axisY = d3.axisLeft()
      .scale(this.scaleY)
      .tickFormat((d, i) => {
        return (d / 10000) + '万';
      })
      .tickPadding(2)
      .ticks(5)
      ;
    
    this.axisY
      .attr('class', 'axis-y')
      .attr('transform', 'translate(' + this.left + ', 0)')
      .style("display", "none")
      .call(axisY)
      ;
  }

  // チャートのライン描画
  render() {
    var line = d3.line()
      .x((d, i) => {
        return this.scaleX(new Date(d.date))|0;
      })
      .y((d, i) => {
        return this.scaleY(d.value)|0;
      })
      ;

    // チャートのライン以下にあるグラデーション
    const linearGradient = this.element.append("defs")
      .append("linearGradient")
      .attr('id', `linear-gradient-${this.randomHash}`)
      .attr("gradientTransform", "rotate(90)");
    
    // グラデの上の色
    linearGradient
      .append("stop")
      .attr("offset", "0%")
      .attr("stop-color", '#bcd2ff');
    // グラデの下の色
    linearGradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", '#FFFFFF');

    // チャート下のグラデのエリア設定
    this.pathGradient
      .datum(this.data)
      .style('stroke-linecap', 'round')
      .style('stroke-linejoin', 'round')
      .attr("fill", `url(#linear-gradient-${this.randomHash})`)
      .attr("opacity", "1")
      .transition()
      .duration(420)
      .ease(d3.easeExpOut)
      .attr("d", d3.area()
        .x((d) => { return this.scaleX(new Date(d.date))|0; })
        .y1((d) => { return this.scaleY(d.value)|0; })
        .y0(this.scaleY(this.min))
      );
    
    this.path
      .datum(this.data)
      .attr("class", "line")
      .style("stroke", this.lineColor)
      .style("stroke-width", "4px")
      .style('fill', 'none')
      .style('stroke-linecap', 'round')
      .style('stroke-linejoin', 'round')
      .transition()
      .duration(420)
      .ease(d3.easeExpOut)
      .attr('d', line)
      ;
  }

  // グラフを空白にする
  clear() {
    this.element.html("");
    this.removeAllListeners('update');
  }

  // 初期化
  init(options) {
    // 再描画に必要なデータを初期化
    if (options.data) {
      this.data = options.data
    }

    var max = d3.max(this.data, function(d) {
      return d.value;
    });
    var min = d3.min(this.data, function(d) {
      return d.value;
    });
    var delta = max - min;
    max = max + (delta * 0.14);
    // 最小値がグラフ底辺にならないように調整
    min = min - (delta * 0.14);

    this.element.attr('width', options.width);
    this.element.attr('height', options.height);

    this.width = options.width;
    this.height = options.height;
    this.max = max;
    this.min = min;

    const padding = 36;
    const paddingTop = 20;
    const paddingRight = 20;
    this.left = 5;
    this.right = this.width-paddingRight;
    this.top = paddingTop;
    this.bottom = this.height-padding;

    // 軸の再設定
    this.setAxisX(0, 12);
    this.setAxisY(this.min, this.max);

    // カーソルが現在どこを指しているか保持しておく
    this.hitDate = '';

    // カーソルと一致する縦のライン再設定
    const CircleSize = 10;
    this.verticalLine
      .attr('x1', 100)
      .attr('y1', this.scaleY(this.min))
      .attr('x2', 100)
      .attr('y2', this.scaleY(this.max))
      .style('stroke', '#92AEC2')
      .style('stroke-width', 1)
      .style('stroke-dasharray', 6)
    ;
    this.verticalLine.style('display', 'none');

    this.verticalCircle
      .attr('r', CircleSize)
      .style('fill', `url(#radial-gradient-circle-${this.randomHash}`)
      .style('display', 'none');
  }

  // 再描画
  reRender() {
    // 縦軸をもし表示していたら消す
    this.verticalLine.style('display', 'none');
    this.verticalText.style('display', 'none');
    this.verticalCircle.style('display', 'none');

    // 再描画
    this.render();
  }
}

export default Chart;