import '~/styles/main.less';

import $ from 'jquery';
global['$'] = $

import dayjs from '~/plugins/dayjs.js';
global['dayjs'] = dayjs

import Chart from '~/plugins/chart.js';
global['Chart'] = Chart

import marked from 'marked';
global['marked'] = marked

import firebase from '~/plugins/firebase.js';
global['firebase'] = firebase

import flarebase from '~/scripts/flarebase.js';
global['flarebase'] = flarebase

import app from '~/scripts/app.js';
global['app'] = app

import store from '~/scripts/store.js';
global['store'] = store
