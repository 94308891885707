const riot = require('riot');

riot.tag2('module-calculate', '<div class="bg-white rounded-8 p16 w-full box-shadow-primary"> <div class="lh15 bold"> <h2 class="fs18 mb11">{opts.currency.data.name_ja} ({opts.currency.data.symbol}) のレート計算</h2> <form class="rounded-12 border overflow-hidden"> <div class="f fbw fm p12"> <div class="f fm w100"><img class="mr8 block s30 object-fit-cover" riot-src="{app.utils.getImgixImageUrl(opts.currency.data.image.url, 30, 30)}" loading="lazy" alt="{opts.currency.data.symbol}"> <div class="fs12">{opts.currency.data.symbol}</div> </div> <input class="text-right overflow-x-scroll" ref="cryptocurrency" type="tel" placeholder="0" oninput="{onCurrencyRateCalculation}" value="" onfocus="{onResetValue}"> </div> <div class="f fbw fm bg-primary_pale_dark p12"> <div class="f fm w100"><img class="mr8 block s32 object-fit-cover" if="{currencyToCompare}" riot-src="/images/currencies/{currencyToCompare.toLowerCase()}.svg" loading="lazy" alt="{currencyToCompare}"> <div class="fs12">{currencyToCompare}</div> </div> <input class="text-right overflow-x-scroll" ref="currency" type="tel" placeholder="0" oninput="{onCryptocurrencyRateCalculation}" value="" onfocus="{onResetValue}"> </div> </form> </div> </div>', '', '', function(opts) {
    this.preload = async({req, res}) => {
      var currencyToCompare = app.utils.selectedCurrency.getId();
      return {
        currencyToCompare,
      };
    };

    this.on('update', () => {
      var prevCurrency = this.currencyToCompare;
      this.currencyToCompare = app.utils.selectedCurrency.getId();

      if (prevCurrency !== this.currencyToCompare && this.refs.currency.value !== '') {
        var cryptocurrencyValue = this.refs.cryptocurrency.value;

        var formattedNumber = this.formatPrice(cryptocurrencyValue * this.getCurrencyPrice());
        var cryptocurrencyValue = Number(this.refs.cryptocurrency.value);

        this.refs.currency.value = app.utils.localizeByCurrency(this.currencyToCompare.toLowerCase(), formattedNumber);
        this.refs.cryptocurrency.value = app.utils.localizeByCurrency(this.currencyToCompare.toLowerCase(), cryptocurrencyValue);
      }
    });

    this.formatPrice = (price) => {
      var regExp = /[^0-9\-\.]/g;
      if (Number.isNaN(price)) {
        return 0;
      }
      else {

        if (regExp.test(price.toString())) {
          return 'NaN';
        }
        else {
          return price;
        }
      }
    }

    this.getCurrencyPrice = () => {
      return this.opts.currency.data[this.currencyToCompare.toLowerCase() + '_price'];
    }

    this.onCurrencyRateCalculation = () => {

      var cryptocurrencyValue = app.utils.convertLocaleStringToNumber(this.refs.cryptocurrency.value);
      var formattedNumber = this.formatPrice(cryptocurrencyValue * this.getCurrencyPrice());
      this.refs.currency.value = app.utils.localizeByCurrency(this.currencyToCompare.toLowerCase(), formattedNumber);
    }

    this.onCryptocurrencyRateCalculation = () => {

      var currencyValue = app.utils.convertLocaleStringToNumber(this.refs.currency.value);
      this.refs.cryptocurrency.value = this.formatPrice(currencyValue / this.getCurrencyPrice());
    }

    this.onResetValue = () => {
      this.refs.currency.value = "";
      this.refs.cryptocurrency.value = "";
    }
});