const riot = require('riot');

riot.tag2('module-categories-list', '<div class="f"><a class="bg-primary_pale_dark border border-primary_pale_dark rounded-4 text-primary fs12 lh15 px8 flex-fixed mr16" href="/cryptocurrency-category">カテゴリ一覧</a> <div class="f fm overflow-x-scroll scrollbar-none"> <div class="rounded-4 px8 fs12 lh15 flex-fixed cursor-pointer mr16 mr0-last {category === item.id ? \'bg-primary text-white\' : \' border border-base_light text-base_light\'}" each="{item in categories}" onclick="{onTriggerSelect}">{item.data.name}</div> </div> </div>', 'module-categories-list,[data-is="module-categories-list"]{display:block}', '', function(opts) {
    this.preload = async({req, res}) => {
      var categories = await app.store.tags.index();

      categories = categories.filter(item => item.data.is_category);

      var category = opts.category.id;

      return {
        categories,
        category
      };
    };

    this.onTriggerSelect = (e) => {
      e.preventDefault();

      this.category = e.item.item.id;

      this.trigger('select', {
        category: e.item.item.id,
      });
    };
});