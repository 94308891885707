const riot = require('riot');

riot.tag2('module-columns', '<div class="bg-white rounded-8 box-shadow-main pt18 px24 pb24 s-px16"> <h2 class="fs18 lh15 mb11">{opts.currency.data.name_ja} {opts.currency.data.name_en} {opts.currency.data.symbol} に関するコラム・記事</h2> <div class="row mb8 mxn8 s-mb0"> <div class="col4 px8 mb16 s-col12" each="{item, i in app.utils.currencySingle.getShowItems(getShowArticles(), limit, opts.isdetail)}"><a class="block h-full s-h-auto bg-white box-shadow-main rounded-8 overflow-hidden" href="{spat.config.media_domain}/articles/{item.slug}"> <div class="f fclm fbw h-full"><img class="block w-full flex-fixed object-fit-cover h114 anim-appear" riot-src="{app.utils.getImageUrl(item.image)}" alt="thumbnail" loading="lazy"> <div class="p12 f fclm fbw h-full"> <div class="text-primary bold lh14 line-clamp-3 word-break-all mb6 s-line-clamp-2">{item.title}</div> <div class="fs12 text-base_pale">{app.utils.formatDate.days(item.published_at)}</div> </div> </div></a></div> </div> <button class="w-full f fh text-primary" if="{opts.columns.length &gt; sliceNumber &amp;&amp; opts.isdetail}" onclick="{onReadMore}"> <div class="fs12 mr8">さらにもっと見る</div><i class="icon-arrow_down fs8"></i> </button> <div class="f fr text-primary pb8" if="{!opts.isdetail}"><a class="f fm" href="/cryptocurrency/{opts.currency.data.slug}/news"> <div class="fs12 mr8">{opts.currency.data.name_ja} ({opts.currency.data.symbol}) のコラム・記事をもっと見る</div><i class="icon-arrow_right fs8"></i></a></div> </div>', '', '', function(opts) {
    this.limit = 6;
    this.sliceNumber = 6;

    this.getShowArticles = () => {
      return this.opts.columns.slice(0, this.sliceNumber);
    };

    this.onReadMore = () => {
      this.sliceNumber += this.limit;
      this.update()
    };
});