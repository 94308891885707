const riot = require('riot');

riot.tag2('atom-stars', '<div class="f fm"> <div class="mr2 mr0-last" each="{star, i in stars}"><img class="block object-fit-contain" riot-src="/images/stars/star_{star.type}.svg" riot-style="width: {opts.size}px" loading="lazy" alt=""></div> </div>', '', '', function(opts) {

    this.on('mount', () => {
      this.update();
    });

    this.on('update', () => {

      this.stars = [];

      var star = this.opts.score;

      while (star >= 1) {
        this.stars.push({
          type: 'fill',
        });

        star -= 1;
      }

      if (star > 0) {
        this.stars.push({
          type: 'half',
        });
      }

      while (this.stars.length < 5) {
        this.stars.push({
          type: 'empty',
        });
      }
    });
});