const riot = require('riot');

riot.tag2('module-breadcrumbs', '<div class="w-full overflow-x-scroll scrollbar-none white-space-nowrap fs11 lh18 py8 f fm"> <div class="f fm" each="{item,index in items}"><a class="{(index &lt; items.length-1) ? &quot;text-primary text-decoration-underline mr4&quot; : &quot;text-base_pale mr12 pointer-none&quot;}" href="{item.link}">{item.label}</a><i class="icon-arrow_right fs11 text-base_pale mr8" if="{index &lt; items.length-1}"></i></div> </div>', '', '', function(opts) {
    this.preload = ({req, res}) => {

      this.isTabPushed = false;
      var tagName = this.parent.opts.dataIs;

      this.items = [
        {
          label: 'TOP',
          link: spat.config.media_domain,
        }
      ];

      if (tagName === 'page-cryptocurrency-list') {
        this.items.push({
          label: '価格レート・チャート一覧',
          link: '/',
        });
        if (req.query.sort) {
          if (app.utils.isAvailableSortType(req.query.sort)) {
            this.sortType = req.query.sort;
            this.pushSortType(req.query.sort);
          }
        }
      }

      else if (tagName === 'page-cryptocurrencies-single') {
        this.items.push(
          {
            label: '価格レート・チャート一覧',
            link: '/',
          },
          {
            label: this.opts.currency.data.name_en,
            link: `/cryptocurrency/${this.opts.currency.data.slug}`,
          },
        );
        if (req.params.type) {

          if (req.params.type !== "basic") {
            this.isTabPushed = true;
          }
          this.pushTab(req.params.type);
        }
      }

      if (tagName === 'page-exchange-list') {
        this.items.push({
          label: '仮想通貨取引所',
          link: '/exchange',
        });
      }
      else if (tagName === 'page-exchange-single') {
        this.items.push(
          {
            label: '仮想通貨取引所',
            link: '/exchange',
          },
          {
            label: this.opts.exchange.data.name_en,
            link: `/exchange/${this.opts.exchange.data.slug}`,
          },
        );
        if (req.params.type) {

          if (req.params.type !== "basic") {
            this.isTabPushed = true;
          }
          this.pushTab(req.params.type);
        }
      }

      else if (tagName === 'page-cryptocurrency-category') {
        this.items.push(
          {
            label: '価格レート・チャート一覧',
            link: '/',
          },
          {
            label: '仮想通貨カテゴリ一覧',
            link: '/cryptocurrency-category'
          }
        );
      }

      else if (tagName === 'page-cryptocurrency-category-single') {
        this.items.push(
          {
            label: '価格レート・チャート一覧',
            link: '/',
          },
          {
            label: '仮想通貨カテゴリ一覧',
            link: '/cryptocurrency-category'
          },
        );
        if (req.params.id) {

          if (!opts.category.data) {
            throw {statusCode: 404 };
          }
          this.categoryType = req.params.id;
          this.items.push(
            {
              label: opts.category.data.name,
              link: `/cryptocurrency-category/${opts.category.id}`,
            },
          );
        }
      }
    };

    this.syncSortType = (type) => {

      if (this.sortType) {
        this.items.pop();
      }

      if (type === "market_cap") {
        this.sortType = '';
      } else {
        this.sortType = type;
        this.pushSortType(type);
      }
      this.update();
    };

    this.pushSortType = (sortType) => {
      var type = app.utils.currencyTable.sortTypes.find(type => type.id === sortType);
      this.items.push({
        label: type.label,
      });
    };

    this.syncTabs = (id) => {
      if (this.isTabPushed) {
        this.items.pop();
      }
      if (id === "basic") {
        this.isTabPushed = false;
      }
      else {
        this.pushTab(id);
        this.isTabPushed = true;
      }
    };

    this.pushTab = (id) => {
      var tagName = this.parent.opts.dataIs;
      if (tagName === 'page-cryptocurrencies-single') {
        var type = app.utils.currencySingle.tabs.find(tab => tab.id === id);
        this.items.push({
          label: `${this.opts.currency.data.name_en} ${type.label}`
        });
      }
      else if (tagName === 'page-exchange-single') {
        var type = app.utils.exchangeSingle.tabs.find(tab => tab.id === id);
        this.items.push({
          label: type.label
        });
      }
    };

    this.pushCategoryType = (id) => {
      if (this.categoryType) {
        this.items.pop();
        this.items.push({
          label: id.data.name,
          link: `/cryptocurrency-category/${id.id}`,
        });
      }
      this.update();
    };
});