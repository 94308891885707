const riot = require('riot');

riot.tag2('atom-tabs', '<div class="f overflow-scroll scrollbar-none box-shadow-primary {opts.isrounded ? &quot;&quot; : &quot;rounded-8&quot;}"> <div class="bg-white f fh relative w-full py16 px24 bw4 border-bottom fs12 bold cursor-pointer word-break-keep {tab.id === current ? &quot;text-primary border-primary&quot; : &quot;text-base_light border-transparent&quot;}" ref="tab" each="{tab in opts.items}" onclick="{onChangeTab}">{tab.label}</div> </div>', '', '', function(opts) {
    this.setCurrent = (id) => {
      this.current = id;
      this.update();
    };

    this.onChangeTab = (e) => {
      this.current = e.item.tab.id;

      this.trigger('change', {
        current: this.current,
      });
    };

    this.getWidth = () => {
      var width = 0;
      this.refs.tab.forEach(tab => {
        width += tab.offsetWidth;
      });
      return width;
    };
});