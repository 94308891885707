var Firerest = require('firerest');

const api = Firerest.create({
  api: spat.config.media_api.endpoint,
  debug: spat.config.env !== 'production',
});

api.cryptocurrencies = {
  getMediaArticles: async(media_slugs) => {
    var {articles} = await app.media_api.child(`/site/articles/multi`).get({slugs: media_slugs});
    return articles;
  },
}

export default api;