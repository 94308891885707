const riot = require('riot');

riot.tag2('atom-amount-display', '<div><span if="{currency === \'USD\' || currency === \'JPY\'}">{currency === \'USD\' ? \'$\' : \'¥\'}</span><span>{opts.riotValue}</span><span if="{currency === \'BTC\'}">BTC</span></div>', '', '', function(opts) {
    this.on('mount', () => {
      this.currency = opts.unit || app.utils.selectedCurrency.getId();
      this.update();
    });

    this.on('update', () => {
      this.currency = opts.unit || app.utils.selectedCurrency.getId();
    });
});