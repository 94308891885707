
export default {
  '/': {
    tag: 'page-cryptocurrency-list',
    // ssr: false,
  },
  '/cryptocurrency': {
    tag: 'page-cryptocurrency-list',
    // ssr: false,
  },
  '/cryptocurrency/:id/chart-preview': {
    tag: 'page-cryptocurrencies-chart',
    // ssr: false,
  },
  '/cryptocurrency-category': {
    tag: 'page-cryptocurrency-category',
    // ssr: false,
  },
  '/cryptocurrency-category/:id': {
    tag: 'page-cryptocurrency-category-single',
    // ssr: false,
  },
  '/cryptocurrency/:id/:type?': {
    tag: 'page-cryptocurrencies-single',
    // ssr: false,
  },
  '/exchange': {
    tag: 'page-exchange-list',
    // ssr: false,
  },
  '/exchange/:id/:type?': {
    tag: 'page-exchange-single',
    // ssr: false,
  },
};