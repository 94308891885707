const riot = require('riot');

riot.tag2('module-market-info', '<div class="bg-white pt18 px12 pb16 rounded-8 box-shadow-main" ref="body"> <h2 class="fs18 lh15 mb12">当日 価格・マーケット詳細情報</h2> <div class="pb14" each="{information in getItems().slice(0, 2)}"> <div data-is="item-information-section_" item="{information}" currency="{opts.currency}"></div> </div> <div class="overflow-hidden" ref="contents" riot-style="max-height: {isOpen ? refs.contents.scrollHeight : 0}px"> <div class="pb14" each="{information in getItems().slice(2)}"> <div data-is="item-information-section_" item="{information}" currency="{opts.currency}"></div> </div> </div> <button class="relative w-full bg-primary_pale rounded-12 text-primary py16 f fh" onclick="{onToggleOpen}">{isOpen ? \'閉じる\' : \'もっと見る\'} <div class="absolute t0 r0 h-full f fh px14"> <div class="icon-triangle_{isOpen ? &quot;up&quot; : &quot;down&quot;}"></div> </div> </button> </div>', '', '', function(opts) {
    this.preload = async({req, res}) => {

      var selectedCurrency = app.utils.selectedCurrency.getId().toLowerCase();

      if (selectedCurrency === 'btc' && opts.currency.data.symbol === 'BTC') selectedCurrency = 'jpy';

      var mainCurrency = opts.currency.id;
      var yesterdayData = {};
      var promises = ['jpy', 'usd', 'btc'].map(async(key) => {
        var items = await app.store.pairs.index({pair: `${mainCurrency}_${key}`, term: 'day', limit: 1});
        yesterdayData[key] = items[0];
      });
      await Promise.all(promises);
      return {
        selectedCurrency,
        yesterdayData
      }
    };

    this.getItems = () => {
      var data = opts.currency.data;
      return [
        {
          sectionTitle: '今日の価格',
          sectionItems: [
            {
              label: '現在価格',
              type: 'amount-display',

              value: app.utils.checkHasData(data[`${this.selectedCurrency}_price`]) && app.utils.getPriceText(data[`${this.selectedCurrency}_price`]),
              unit: this.selectedCurrency.toUpperCase(),
            },
            {
              label: '24H価格変動',
              type: 'percentage-display_',
              value: app.utils.checkHasData(data[`${this.selectedCurrency}_percent_change_24h`]) && {
                amount: this.getChangedAmount(),
                percentage: (data[`${this.selectedCurrency}_percent_change_24h`]).toFixed(2),
              },
              unit: this.selectedCurrency.toUpperCase(),
            },

            {
              label: '24H取引高',
              type: 'amount-display',

              value: app.utils.checkHasData(data[`${this.selectedCurrency}_volume_24h`]) && app.utils.localize(data[`${this.selectedCurrency}_volume_24h`]),
              unit: this.selectedCurrency.toUpperCase(),
            },
            {
              label: '市場優勢',
              value: app.utils.checkHasData(data['dominance']) && data['dominance'].toFixed(2) + '%',
              description_key: 'dominance',
            },
            {
              label: '市場ランキング',
              value: app.utils.checkHasData(data['rank']) && '#' + data['rank'],
              description_key: 'rank',
            },
          ],
        },
        {
          sectionTitle: '時価総額',
          sectionItems: [
            {
              label: '時価総額',

              type: 'amount-display',
              value: app.utils.checkHasData(data[`${this.selectedCurrency}_market_cap`]) && app.utils.getPriceText(data[`${this.selectedCurrency}_market_cap`]),
              unit: this.selectedCurrency.toUpperCase(),
              description_key: 'market_cap',
            },
            {
              label: '完全希薄化の\n時価総額',

              type: 'amount-display',
              value: app.utils.checkHasData(data[`${this.selectedCurrency}_dilution_market_cap`]) && app.utils.getPriceText(data[`${this.selectedCurrency}_dilution_market_cap`]),
              unit: this.selectedCurrency.toUpperCase(),
              description_key: 'dilution_market_cap',
            },
          ],
        },
        {
          sectionTitle: '昨日の価格',
          sectionItems: [
            {
              label: '昨日の高値 / 安値',
              type: 'compare-display_',
              value: this.hasCandleStickData() && {
                left: app.utils.getPriceText(this.yesterdayData[this.selectedCurrency].data.high),
                right: app.utils.getPriceText(this.yesterdayData[this.selectedCurrency].data.low)
              },
              unit: this.selectedCurrency.toUpperCase(),
            },
            {
              label: '昨日の始値 / 終値',
              type: 'compare-display_',
              value: this.hasCandleStickData() && {
                left: app.utils.getPriceText(this.yesterdayData[this.selectedCurrency].data.open),
                right: app.utils.getPriceText(this.yesterdayData[this.selectedCurrency].data.close),
              },
              unit: this.selectedCurrency.toUpperCase(),
            },
            {
              label: '昨日の変動',
              type: 'change-number',
              value: this.hasCandleStickData() && (this.calcChangeRate() * 100).toFixed(2),
              unit: '%',
            },
          ],
        },
        {
          sectionTitle: '供給',
          sectionItems: [
            {
              label: '循環サプライ',
              value: app.utils.checkHasData(data.circulating_supply) && app.utils.localize(data.circulating_supply) + data.symbol,
              unit: this.selectedCurrency.toUpperCase(),
              description_key: 'circulating_supply',
            },
            {
              label: '総合サプライ',
              value: app.utils.checkHasData(data.total_supply) && app.utils.localize(data.total_supply) + data.symbol,
              unit: this.selectedCurrency.toUpperCase(),
              description_key: 'total_supply',
            },
            {
              label: '最大供給',
              value: app.utils.checkHasData(data.max_supply) && app.utils.localize(data.max_supply) + data.symbol,
              unit: this.selectedCurrency.toUpperCase(),
              description_key: 'max_supply',
            },
          ],
        },
      ];
    };

    this.on('update', () => {
      this.updateCurrency();
    });

    this.updateCurrency = async() => {
      var prevCurrency = this.selectedCurrency;
      this.selectedCurrency = app.utils.selectedCurrency.getId().toLowerCase();

      if (this.selectedCurrency === 'btc' && opts.currency.data.symbol === 'BTC') this.selectedCurrency = 'jpy';

      if (this.selectedCurrency !== prevCurrency) this.update();
    };

    this.getChangedAmount = () => {
      var percentChange = opts.currency.data[`${this.selectedCurrency}_percent_change_24h`];
      var price = opts.currency.data[`${this.selectedCurrency}_price`];
      var amount =  price * percentChange / (percentChange + 100);
      return app.utils.getPriceText(amount);
    };

    this.calcChangeRate = () => {
      var data = this.yesterdayData[this.selectedCurrency].data;

      return (data.close / data.open) - 1;
    };

    this.onToggleOpen = () => {
      this.isOpen = !this.isOpen;
    };

    this.hasCandleStickData = () => {
      return this.yesterdayData && this.yesterdayData[this.selectedCurrency];
    };

});
riot.tag2('item-information-section_', '<h3 class="bold fs16 lh15">{opts.currency.data.name_ja} ({opts.currency.data.symbol}) の{opts.item.sectionTitle}</h3> <div each="{item in opts.item.sectionItems}"> <div class="h44 f fbw fm border-bottom"> <div class="f fm px12"> <div class="fs12 mr4 white-space-pre-wrap lh16">{item.label}</div> <div data-is="atom-word-description" description="{app.utils.descriptions[item.description_key]}" if="{item.description_key}"></div> </div> <div class="fs12" if="{item.value &amp;&amp; item.type}"> <div class="{item.type === \'change-number\' ? \'\': \'bold\'}" if="{item.type !== \'change-number\'}" data-is="atom-{item.type}" riot-value="{item.value}" unit="{item.unit}"></div> <div if="{item.type === \'change-number\'}"> <div class="f fh text-{item.value &lt; 0 ? \'minus\' : \'plus\'}"> <div class="h-full fs10 mr4 icon-{item.value &lt; 0 ? \'triangle_down\' : \'triangle_up\'}"></div> <div>{app.utils.localizeFixed(Math.abs(item.value))}%</div> </div> </div> </div> <div class="fs12 bold" if="{item.value &amp;&amp; !item.type}">{item.value}</div> <div if="{!item.value}">---</div> </div> </div>', '', '', function(opts) {
});
riot.tag2('atom-percentage-display_', '<div class="f fr mb6 bold" data-is="atom-amount-display" riot-value="{opts.riotValue.amount}" unit="{opts.unit}"></div> <div class="f fr fm fw5"> <div class="f fh text-{opts.riotValue.percentage &lt; 0 ? \'minus\' : \'plus\'}"> <div class="h-full fs10 mr4 icon-{opts.riotValue.percentage &lt; 0 ? \'triangle_down\' : \'triangle_up\'}"></div> <div>{app.utils.localizeFixed(Math.abs(opts.riotValue.percentage))}%</div> </div> </div>', '', '', function(opts) {
});
riot.tag2('atom-compare-display_', '<div class="f fm bold"> <div data-is="atom-amount-display" riot-value="{opts.riotValue.left}" unit="{opts.unit}"></div> <div>/</div> <div data-is="atom-amount-display" riot-value="{opts.riotValue.right}" unit="{opts.unit}"></div> </div>', '', '', function(opts) {
});