import dayjs from "dayjs";

const utils = {
  currencies: ["JPY", "USD", "BTC"],
  smallYenValue: 100,
  currencyTable: {
    limit: 100,
    sortTypes: [
      {
        label: '時価総額',
        id: 'market_cap',
        key: 'market_cap',
        order: 'desc',
        title: '時価総額ランキング',
        description: '主要仮想通貨(暗号資産)の価格レート・チャートを時価総額順で一覧化しています。',
        metaTitle: '時価総額ランキング',
        metaDescription: 'ビットコイン(BTC)、イーサリアム(ETH)、リップル(XRP)、ネム(XEM)等の主要仮想通貨(暗号資産)の価格レート・チャートを時価総額順で一覧化しています。',
        keywords: '時価総額'
      },
      {
        label: '値上がり順',
        id: 'increase',
        key: 'percent_change_24h',
        order: 'desc',
        title: '値上がり率ランキング',
        description: '主要仮想通貨(暗号資産)の価格レート・チャートを値上がり順で一覧化しています。',
        metaTitle: '値上がり率ランキング',
        metaDescription: 'ビットコイン(BTC)、イーサリアム(ETH)、リップル(XRP)、ネム(XEM)等の主要仮想通貨(暗号資産)の価格レート・チャートを値上がり順で一覧化しています。',
        keywords: '値上がり'
      },
      {
        label: '値下がり順',
        id: 'decrease',
        key: 'percent_change_24h',
        order: 'asc',
        title: '値下がり率ランキング',
        description: '主要仮想通貨(暗号資産)の価格レート・チャートを値下がり順で一覧化しています。',
        metaTitle: '値下がり率ランキング',
        metaDescription: 'ビットコイン(BTC)、イーサリアム(ETH)、リップル(XRP)、ネム(XEM)等の主要仮想通貨(暗号資産)の価格レート・チャートを値下がり順で一覧化しています。',
        keywords: '値下がり'
      },
      {
        label: '活況銘柄',
        id: 'volume',
        key: 'volume_24h',
        order: 'desc',
        title: '活況銘柄ランキング',
        description: '主要仮想通貨(暗号資産)の価格レート・チャートを出来高順で一覧化しています。',
        metaTitle: '活況銘柄情報',
        metaDescription: 'ビットコイン(BTC)、イーサリアム(ETH)、リップル(XRP)、ネム(XEM)等の主要仮想通貨(暗号資産)の価格レート・チャートを出来高順で一覧化しています。',
        keywords: '活況,出来高'
      },
      {
        label: '新規追加銘柄',
        id: 'new',
        key: 'created_at',
        order: 'desc',
        title: '新規追加銘柄一覧',
        description: '新規に追加した仮想通貨(暗号資産)を一覧化しています。',
        metaTitle: '新規追加銘柄一覧',
        metaDescription: '新規に追加した仮想通貨(暗号資産)を一覧化しています。',
        keywords: '新規追加'
      },
    ],
    filterTypes: [
      {label: "時価総額", id: "market_cap"},
      {label: "価格", id: "price"},
      {label: "前日比", id: "percent_change_24h"},
      {label: "出来高", id: "volume_24h"},
    ],
    items: {
      number: {
        id: 'number',
        label:'#',
        class: 'sticky l0 z3',
        width: 'w20' 
      },
      currency: {
        id: 'currency',
        label:'仮想通貨名',
        class: "sticky l36 z3",
        width: 'w88 l-w174 '
      },
      price: {
        id: 'price',
        label:'価格'
      },
      percent_change_24h: {
        id: 'percent_change_24h',
        label:'前日比'
      },
      market_cap: {
        id: 'market_cap',
        label:'時価総額'
      },
      volume_24h: {
        id: 'volume_24h',
        label:'24H出来高'
      },
      circulating_supply: {
        id: 'circulating_supply',
        label:'循環供給量'
      },
      // chart: {
      //   id: 'chart',
      //   label:'7daysチャート画像',
      //   class: 's-rounded-top-right-12',
      // },
    },
  },
  currencySingle: {
    tabs: [
      {id: 'basic', label: '基本情報'},
      {id: 'chart', label: 'チャート'},
      {id: 'exchanges', label: '取扱取引所'},
      {id: 'news', label: 'ニュース'},
      {id: 'historical', label: '時系列データ'},
      {id: 'detail', label: '詳細情報'},
    ],
    // リンクのベースアイテム
    links: {
      website: {label: 'ウェブサイト'},
      explorer: {label: 'エクスプローラー'},
      wallet: {label: 'ウォレット'},
      community: {label: 'コミュニティ'},
      code: {label: 'ソースコード'},
    },
    // リンクの中身を紐づける
    getLinkSections: (currency) => {
      var sections = {...app.utils.currencySingle.links};
      //- sectionsオブジェクトに通貨の情報を紐付ける
      Object.keys(sections).forEach(id => {
        //- website => website_1_txtなど、websiteが付くプロパティを抜き出す
        var links = Object.keys(currency.data).filter(key => {
          var regExp = new RegExp(id);
          return regExp.test(key);
        });
        //- 配列を初期化
        sections[id].items = [];
        //- txt / urlを各セクションに紐付ける
        links.forEach((link, index) => {
          var data = {
            txt: currency.data[`${id}_${index+1}_txt`],
            url: currency.data[`${id}_${index+1}_url`],
          };
          //- 空文字なら追加しない
          if (data.txt) {
            sections[id].items[index] = data;
          }
        });
        // itemが0個のときは取り除く
        if (!sections[id].items.length) {
          delete sections[id];
        }
      });
      return sections;
    },
    getTabs: (currency, exchange_pairs) => {
      var tabs = app.utils.currencySingle.tabs;
      //- ローソク足データの有無でチャート、タブの表示内容を切り替える
      var hasCandleSticks = currency.data.exchange_by_pair;
      if (!hasCandleSticks) {
        tabs = tabs.filter(tab => tab.id !== 'historical' && tab.id !== 'chart');
      }
      //- newsタブを出すかどうか
      if (!currency.data.feed_count && !currency.data.relation_media_article_slugs.length) {
        tabs = tabs.filter(tab => tab.id !== 'news');
      }
      //- 取引所タブを出すかどうか
      if (!exchange_pairs.length) {
        tabs = tabs.filter(tab => tab.id !== 'exchanges');
      }

      return tabs;
    },
    // 選択しているタブによって表示件数を絞る
    getShowItems: (items, limit, isdetail=false) => {
      return isdetail ? items : items.slice(0, limit);
    },
    getCurrencyCategories: async (currency) => {
      var tag_promises = currency.data.tags.map(tag => {
        return app.store.tags.get(tag);
      });
      var categories = await Promise.all(tag_promises);
      return categories.filter(category => !category.data.disabled);
    },
  },
  historicalDataTable: {
    thead: [
      '日付',
      '始値',
      '高値',
      '安値',
      '終値',
      '出来高',
    ],
  },
  exchangeSingle: {
    tabs: [
      {label: '基本情報', id: 'basic', description_title: '基本情報'},
      {label: '取扱通貨', id: 'pair', description_title: '取扱通貨'},
      //- 口コミ・評判・レビューだとレイアウト崩れるため仮文言
      {label: '口コミ', id: 'review', description_title: '口コミ・評判・レビュー'},
      {label: 'ニュース', id: 'feed', description_title: 'ニュース'},
    ],
    getTabs: (exchange, exchange_pairs) => {
      var tabs = app.utils.exchangeSingle.tabs;
      if (!exchange_pairs.length) {
        tabs = tabs.filter(tab => tab.id !== 'pair');
      }
      //- 口コミタブの表示/非表示
      if (!exchange.data.review_count) {
        tabs = tabs.filter(tab => tab.id !== 'review');
      }
      //- ニュースタブの表示/非表示
      if (!exchange.data.feed_count) {
        tabs = tabs.filter(tab => tab.id !== 'feed');
      }
      return tabs;
    },
    // 選択しているタブによって表示件数を絞る
    getShowItems: (items, limit, isdetail=false) => {
      return isdetail ? items : items.slice(0, limit);
    },
  },
  selectedCurrency: {
    getId: () => {
      //- localStorageに選択済み通貨があれば表示更新それ以外はJPYを登録
      if (spat.isNode) {
        return 'JPY';
      } else {
        return localStorage.getItem('selectedCurrency') || 'JPY';
      }
    },
    setId: (currency) => {
      if (spat.isBrowser) {
        localStorage.setItem('selectedCurrency', currency);
        const { currentPageTag } = spat.appTag.navTag;
        if (currentPageTag) {
          currentPageTag.trigger('selectedCurrencyChange', { currency });
        }
        riot.update();
      }
    },

  },
  // imgix画像の取得
  getImgixImageUrl: (image, width, height=null) => {
    var dpr = (typeof devicePixelRatio === 'number') ? devicePixelRatio : 1;
    var url = `${image}?auto=format&dpr=${dpr}&w=${width}`;
    if (height) {
      url += `&h=${height}`
    }
    return url;
  },
  // 画像のサイズを最適化する、sizeがない場合は、オリジナルurlを表示する
  getImageUrl: (image, sizeKey = 'thumbnail') => {
    if(!image || !image.url) {
      return '/images/media_default.svg';
    } else {
      return image.sizes ? image.sizes[sizeKey].url : image.url;
    }
  },
  //- localize + 整数にする
  localize: (num) => {
    return Math.floor(num).toLocaleString();
  },
  //- localize + 任意の位に丸める
  localizeFixed: (num, maxDigits=2, minDigits=2) => {
    return num.toLocaleString(undefined, {minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits});
  },
  // 少数点以下、0以外の数字が出てから任意の桁数分表示
  localizeDecimal: (num, digits) => {
    if (num === 0) return '0';
    return app.utils.stringToDecimal(num.toString(), digits);
  },
  // 日本円かつ、基準値を上回っていれば丸める、usd/btcなら少数点第2位で四捨五入
  localizeByCurrency: (currency, num) => {
    return currency === "jpy" && Math.abs(num) > app.utils.smallYenValue ? app.utils.localize(num) : app.utils.localizeFixed(num)
  },
  // 価格表示のロジック
  getPriceText: (num) => {
    // eが入っていたら展開する
    if (/e/.test(num.toString())) {
      return app.utils.exponentialToNumString(num);
    }
    else if (num === 0) {
      return "--";
    }
    // 0.000... の場合は有効数字4桁
    else if (num < 1) {
      return app.utils.localizeDecimal(num);
    }
    // 99.99999 => '99.99' / 9.99999 => '9.999'
    else if (num < 100) {
      var [above] = num.toString().split('.');
      const DEFAULT_LENGTH = 4;
      const digits = DEFAULT_LENGTH - above.length
      return app.utils.localizeFixed(num, digits, digits);
    }
    // 10,000以下は少数点以下2位まで表示
    else if (num < 10000) {
      return app.utils.localizeFixed(num);
    }
    // それ以上は整数
    else {
      return app.utils.localize(num);
    }
  },
  // 数字の文字列をテーブル表示用に丸める
  stringToDecimal: (numString, digits=4) => {
    var regExp = new RegExp(`0.0*[1-9]\\d{0,${digits - 1}}`);
    var result = numString.match(regExp)
    return result[0];
  },
  // 指数表示を文字列でフル表示する
  exponentialToNumString: (num) => {
    // ['何の', '何乗']に分解
    var numbers = num.toString().split('e');
    var rawNumber = numbers[0];
    var exponentialNumber = Number(numbers[1]);
    // 少数点を取り除く
    rawNumber = rawNumber.replace('.', '');
    var zeros = [];
    var fullString = '';
    // 文字列に0を追加してフル表示に変換
    if (exponentialNumber > 0) {
      // ex: 1e3 => ["0", "0", "0"]を生成
      zeros = Array(exponentialNumber).fill("").map(_ => '0');
      fullString = rawNumber + zeros.join('');
    } else {
      var zeroLength = Math.abs(exponentialNumber) - 1;
      zeros = Array(zeroLength).fill("").map(_ => '0');
      fullString = '0.' + zeros.join('') + rawNumber;
    }
    return app.utils.stringToDecimal(fullString);
  },
  // 通貨データの有効なデータがあるかチェック
  checkHasData: (data) => {
    return data || data === 0;
  },
  //- localeStringを数値型に
  convertLocaleStringToNumber: (string) => {
    string = string.replace(/,/g,"");
    return Number(string);
  },
  descriptions: {
    market_cap: '循環供給量の合計市場価値。\n時価総額=現在の価格X循環供給',
    dilution_market_cap: '最大供給が流通した後の現在価格での時価総額。',
    volume_24h: '過去24時間で取引された仮想通貨量。',
    max_supply: `将来的に発行される仮想通貨の最大量。\nこのデータがプロジェクトによって提出されていない場合、または${spat.config.site_name}によって検証されていない場合、max supplyは次のように表示されます。「-」`,
    dominance: '全ての仮想通貨の総循環時価総額に対する資産の割合。',
    rank: '時価総額のランキング。',
    circulating_supply: '市場で流通し、公開されている仮想通貨の量。',
    total_supply: '発行済の仮想通貨数から、バーンされた仮想通貨数を差し引いた金額。',
    score: `${spat.config.site_name}の取引所スコアは、<a href="https://coinmarketcap.com/" class="text-primary" target="_blank" rel="nofollow noopener">Coinmarketcap</a>のAPIデータにて提供される現物取引が可能な各取引所の点数（0.0〜10.0）を表記しています。`,
    evaluation: 'ユーザーからの取引所に対する意見を集めました。調査内容には「手数料」「取扱通貨」「速度・安定性」「機能・ツール」「サポート体制」が含まれています。',
    rank: `取引所スコアならびユーザー評価を踏まえ、専門家のフィルター、また${spat.config.site_name}の編集チームによる月次調査を元に総合的なランク評価を行います。`,
    tvl: 'DeFi（分散型金融）プロトコルにロックされている暗号資産の合計（Total Value Locked）\n<a href="https://defillama.com/" class="text-primary" target="_blank" rel="nofollow noopener">Defi Llama</a>の値を参考にしています。',
  },
  //- 指定されたデータから高値安値を抽出
  getHighAndLow: (items) => {
    var data = {};
    items.forEach(item => {
      if (!data.high) data.high = item.data.high;
      if (!data.low) data.low = item.data.low;
      if (item.data.high > data.high) {
        data.high = item.data.high;
      }
      if (item.data.low < data.low) {
        data.low = item.data.low;
      }
    });
    return data;
  },
  // オブジェクトを query に変換
  objectToQuery(obj, {sep='&', eq='=', isEncode=true} = {}) {
    return Object.entries(obj).map(([key, value]) => {
      if (isEncode) value = encodeURIComponent(value);
      return `${key}${eq}${value}`;
    }).join(sep);
  },
  categoryTable: {
    limit: 10,
    items: [
      {
        name: "カテゴリ名",
        id: "name",
        class: 'sticky l0 z3',
      },
      {
        name: "平均前日比",
        id: "currency_percent_change_24_average",
        canSort: true,
      },
      {
        name: "上昇率TOP銘柄",
        id: "currency_percent_change_24_top",
      },
      {
        name: "時価総額TOP",
        id: "currency_market_cap_top",
        canSort: true,
      },
      {
        name: "銘柄数",
        id: "ticker_count", 
        canSort: true,
      },
      {
        name: "時価総額",
        id: "currency_market_cap",
        canSort: true,
        information: "各カテゴリにおける\n暗号通貨の流通供給量の市場価値の合計。",
      },
      {
        name: "24H出来高",
        id: "currency_volume24h",
        canSort: true,
        isActive: false,
        information: "各カテゴリにおける\n暗号資産の過去24時間に取引された合計値。",
      },
    ],
  },
  formatDate: {
    days: (unixTime) => {
      return dayjs(unixTime).format('YYYY年M月D日');
    },
    minutes: (unixTime) => {
      return dayjs(unixTime).format('YYYY年M月D日 H:mm');
    },
  },
  isAvailableSortType(sortType) {
    return this.currencyTable.sortTypes.some(item => item.id === sortType);
  },
};

export default utils;