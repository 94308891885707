var Firerest = require('firerest');

const api = Firerest.create({
  api: spat.config.api.endpoint,
  cacheKey: '_token',
  tokenKey: 'Token',
  debug: spat.config.env !== 'production',
  debug: false,
});

api.cryptocurrencies = {
  fetchDetail: async (id) => {
    var items = await app.api.child(`/cryptocurrencies/${id}/detail`).get();
    return items;
  },
  search: async(keyword, limit=20, cursor='') => {
    return await app.api.child('/cryptocurrencies/search').get({keyword, limit, cursor});
  }
},

api.exchanges = {
  fetchDetail: async (id) => {
    var items = await app.api.child(`/exchanges/${id}/detail`).get();
    return items;
  },
}

export default api