const riot = require('riot');

riot.tag2('atom-word-description', '<div class="relative hover-trigger" ref="body"><img class="block s16 cursor-pointer" src="/images/icons/question.svg" loading="lazy" alt=""> <div class="hide absolute z3 hover-show pt20 {opts.side || side}0 w{WIDTH} {opts.top || \'t0\'}"> <div class="f fh bg-base_75per rounded-4 px8 py4 text-left"><span class="fs10 lh15 text-white white-space-pre-wrap word-break-wrap" data-is="atom-raw" content="{opts.description}"></span></div> </div> </div>', '', '', function(opts) {
    this.on('mount', () => {
      this.WIDTH = 200;

      if (spat.isNode) {
        this.side = 'l';
        this.update();
        return;
      }

      setTimeout(() => {
        if (!this.refs.body) return;

        var left = this.refs.body.offsetLeft;
        var width = this.refs.body.offsetWidth;

        this.side = innerWidth - (left + width) < this.WIDTH ? 'r' : 'l';
        this.update();
      }, 512);
      this.update();
    });
});