const riot = require('riot');

riot.tag2('page-cryptocurrency-list', '<div class="f fclm"> <div data-is="module-header" ref="header"></div> <div class="container-1048 h-full"> <div class="px12" data-is="module-breadcrumbs" ref="breadcrumbs"></div> <div class="px12 mb12 s-mb16" data-is="module-description" title="仮想通貨 {sortType.title}" description="{sortType.description}"></div> <div class="px12 mb24" data-is="module-daily-info" item="{daily_info}"></div> <div class="px12 s-pl12 s-pr0" data-is="module-cryptocurrency-table" ref="table" category="{category}"></div> <div class="px12 mb24" data-is="module-markets" items="{exchanges}"></div> <div class="mb24 s-mb56 px12" data-is="module-contact"></div> <div class="mb24 s-mb56 px12" data-is="module-register-email"></div> <div class="mb72" data-is="item-api-reference"></div> </div> <div class="mta" data-is="module-footer"></div> </div> <div data-is="module-btn-page-top"></div>', '', '', function(opts) {
    this.preload = async({req, res}) => {
      var promises = [
        app.store.daily_information.get(),
        app.store.exchanges.index({}),
      ];
      var [daily_info, exchanges] = await Promise.all(promises);

      var isAvailableSort = app.utils.isAvailableSortType(req.query.sort);

      var sort = isAvailableSort ? req.query.sort: 'market_cap';
      var sortType = this.getSortType(sort);

      if (!isAvailableSort && spat.isBrowser) {
        history.replaceState(history.state, null, location.pathname);
      }

      await this.fetchCategory(req.params.id);

      return {
        daily_info,
        exchanges,
        sortType,
      };
    };

    this.on('show', () => {
      this.refs.table.on('sortChange', (id) => {
        this.refs.breadcrumbs.syncSortType(id);

        this.sortType = this.getSortType(id);

        spat.updateMeta();
        this.update();
      });
    });

    this.head = () => {
      var title = `暗号資産(仮想通貨) 価格レート・チャート一覧 - ${this.sortType.metaTitle}`;

      return {
        title: `${title} | ${spat.config.site_name}`,
        description: `${title} - ${this.sortType.metaDescription}`,
        keywords: `暗号資産,仮想通貨,暗号通貨,ビットコイン,btc,bitcoin,価格,レート,チャート,相場,銘柄,一覧,ランキング,${this.sortType.keywords}`,
      };
    };

    this.on('hide', () => {
      this.refs.table.trigger('clearFilter');
      this.update();
    });

    this.fetchCategory = async (id) => {
      this.category = await app.store.tags.get(id);
      this.update();
    };

    this.getSortType = (id) => {
      return app.utils.currencyTable.sortTypes.find(item => item.id === id);
    };
});