import firebase from 'firebase/app';
import 'firebase/firestore';

firebase.initializeApp(spat.config.firebase);


firebase.firestore.DocumentReference.prototype.fetch = async function() {
  var doc = await this.get();
  var item = null;

  if (doc.exists) {
    item = {
      id: doc.id,
      ref: doc.ref,
      doc: doc,
      data: doc.data(),
      relation: {},
    };
  }

  return item;
};


var db = firebase.firestore();
global.db = db;
global.firebase = firebase;

export default firebase;
