const riot = require('riot');

riot.tag2('module-register-email', '<div class="bg-white rounded-8 box-shadow-main pt18 px24 pb24 s-px16 s-pb16"> <h2 class="fs18 lh15 bold mb11">{spat.config.site_name}の最新ニュースをお届けします！</h2> <div class="mb12">こちらからメールアドレスを登録してください。</div> <form onsubmit="{register}"> <div class="f s-flex-wrap"> <input class="w-full h56 rounded-12 bg-white border border-base_weak px12 mr24 s-h48 s-mr0 s-mb16" placeholder="メールアドレスを入力してください" ref="email" required type="email"> <div class="relative f fc s-w-full"> <button class="w192 h56 rounded-12 bg-primary text-white bold s-w-full s-h40" type="submit">登録する</button><i class="icon-arrow_right absolute t20 r18 fs16 text-white s-t12 s-r18"></i> </div> </div> </form> </div>', '', '', function(opts) {
    this.register = async(e) => {
      e.preventDefault();
      try {
        var token = await app.store.registerEmail({email: this.refs.email.value});
        console.log(token);
        spat.modal.alert('メルマガ登録ありがとうございます。');
      }
      catch(error) {
        var res = JSON.parse(error.responseJSON.response.text);
        if (res.title === "Member Exists") {
          spat.modal.alert('既に登録済みのメールアドレスです');
        } else {
          spat.modal.alert('エラーが発生しました');
        }
      }
    };
});