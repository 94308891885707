const riot = require('riot');

riot.tag2('module-news-ranking', '<div class="bg-white px12 pt12 pb14 rounded-8 box-shadow-primary"> <h2 class="fs16 bold lh15 mb13">暗号資産(仮想通貨) ニュース人気ランキング</h2> <div class="border-bottom"> <div class="border-top f" each="{item, index in opts.items}"> <div class="relative icon-rank_label fs20 w20 mtn2 mr8 text-{getLabelColor(index)}"> <div class="absolute trbl0 w-full f fc ft pt5 pr2 text-white fs13 bold font-avenir">{index + 1}</div> </div> <div class="w-full py8"><a class="fs12 word-break-all text-primary mb6 lh15 line-clamp-2" href="{item.link}">{item.title.rendered}</a> <div class="fs12 text-base_pale lh15">{app.utils.formatDate.minutes(item.date)}</div> </div> </div> </div> <div class="pt14 w-full f fr text-right"><a class="text-primary lh15 fs12 word-break-all" href="https://kasobu.com/news/"> <div class="f fm"> <div class="mr8"> <div>暗号資産(仮想通貨)ニュース人気ランキングを</div> <div>もっと見る</div> </div> <div class="icon-arrow_right fs8"></div> </div></a></div> </div>', '', '', function(opts) {
    this.getLabelColor = (index) => {
      switch(index) {
        case 0:
          return 'gold';
          break;
        case 1:
          return 'silver';
          break;
        case 2:
          return 'bronze';
          break;
        default:
          return 'rank_default';
      }
    };
});