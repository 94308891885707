var flarebase = {
  async initializeApp() {
    // firebase.initializeApp(spat.config.firebase);
    this.store.init();
  },



  store: {
    _cache: {},

    init() {
      this.db = firebase.firestore();

      // this.db.settings({
      //   cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
      // });
      // this.db.enablePersistence({synchronizeTabs:true});

      return this;
    },

    collection(path) {
      var ref = this.db.collection(path);
      return ref;
    },

    doc(path) {
      var ref = this.db.doc(path);
      return ref;
    },

    async get(collection, id, opts = {}) {
      // キャッシュを参照する場合
      if (opts.cache !== false) {
        var cache = this.getCache(collection, id);

        // fetch 中だった場合
        if (cache.promise) {
          await cache.promise;
          return cache;
        }
        // fetch が終わっている場合
        else if (cache.fetched) {
          return cache;
        }
        // fetch もキャッシュもしていない場合
        else {
          cache.promise = (async () => {
            var doc = await cache.ref.get();
            var item = await this.normalize(doc, opts);
            return item;
          })();

          var item = await cache.promise;
          delete cache.promise;
          return item;
        }
      }
      // キャッシュを参照しない場合
      else {
        var doc = await flarebase.store.db.collection(collection).doc(id).get();
        var item = await this.normalize(doc, opts);
        return item;
      }
    },

    getCache(collection, id) {
      if (!this._cache[collection]) {
        this._cache[collection] = {};
      }

      if (!this._cache[collection][id]) {
        var ref = flarebase.store.db.collection(collection).doc(id);
        this._cache[collection][id] = {
          id: ref.id,
          ref: ref,
          data: {},
          fetched: false,
        };
      }

      return this._cache[collection][id];
    },

    async find(collection, key, value, opts = {}) {
      var items = await collection.where(key, '==', value).getWithRelation(opts);
      return items[0];
    },

    async getCollection(ref, opts) {
      var ss = await ref.get();

      var promises = ss.docs.map(async (doc) => {
        var item = await this.normalize(doc, opts);
        return item;
      });

      return Promise.all(promises);
    },

    watch(ref, callback, opts) {
      var isNew = false;
      return new Promise((resolve) => {
        var observer = ref.onSnapshot(async (ss) => {
          for (let change of ss.docChanges()) {
            var item = await this.normalize(change.doc, opts);
            callback && callback({ change, item, isNew });
          }
          isNew = true;
          resolve(observer);
        });
      });
    },

    // データ構造整理してついでにキャッシュする
    async normalize(doc, opts = {}) {
      var item = this.getCache(doc.ref.parent.path, doc.id);

      item.doc = doc;
      item.data = doc.data();

      // リレーションの展開
      if (doc.exists && opts.relation !== false) {
        var keys = Object.keys(item.data);

        // 子要素は relation を切る
        var child_opts = Object.assign({}, opts, {
          // relation: false,
        });

        // TODO: これはとりあえずオフっとく
        // for (let key of keys) {
        //   var value = item.data[key];
        //   if (value instanceof firebase.firestore.DocumentReference) {
        //     item.data[ key.replace(/_ref$/, '') ] = await this.get(value.parent.path, value.id, child_opts);
        //   }
        // }
      }

      // ユーザーだった場合
      if (this.callback) {
        this.callback(item);
      }

      item.fetched = true;

      return item;
    },

    async add(collection, opts = {}) {
      var res = await flarebase.store.db.collection(collection).add(opts);
      return res;
    },

    // 更新
    async update(collection, id, opts = {}) {
      var res = await flarebase.store.db.collection(collection).doc(id).update(opts);
      return res;
    },

    // 上書き更新
    async set(collection, id, opts) {
      var res = await flarebase.store.db.collection(collection).doc(id).set(opts);
      return res;
    },

  },
};

flarebase.store.CollectionObserver = class {
  constructor(collection_ref) {
    this.collection_ref = collection_ref;
    this.items = [];
    this.unsubscribe = null;
  }

  async fetchMore() {
    var ref = this.collection_ref;

    if (this.items.length >= 1) {
      var last = this.items[this.items.length - 1];
      ref = ref.startAfter(last.doc);
    }

    ref = ref.limit(32);

    var items = await ref.getWithRelation();
    this.items.push(...items);
  }

  async watch(callback, opts) {
    // 前のを unwatch する
    if (this.unsubscribe) {
      this.unwatch();
    }

    this.items = [];

    this.unsubscribe = await this.collection_ref.watch(({ change, item, isNew }) => {
      if (change.type === 'added') {
        this.items.splice(change.newIndex, 0, item);
      }
      else if (change.type === 'modified') {
        // 古い要素を削除
        this.items.splice(change.oldIndex, 1);
        // 新しい要素を追加
        this.items.splice(change.newIndex, 0, item);
      }
      else if (change.type === 'removed') {
        // 既存のアイテムを取り除く
        this.items = this.items.filter(c => c.id !== item.id);
      }

      callback && callback({
        change, item, isNew
      });
    }, opts);
  }

  unwatch() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }
};

firebase.firestore.DocumentReference.prototype.getWithRelation = function(opts) {
  return flarebase.store.get(this.parent.path, this.id, opts);
};

firebase.firestore.Query.prototype.getWithRelation = function(opts) {
  return flarebase.store.getCollection(this, opts);
};

firebase.firestore.Query.prototype.watch = function(callback, opts) {
  return flarebase.store.watch(this, callback, opts);
};

firebase.firestore.Query.prototype.find = function(key, value, opts) {
  return flarebase.store.find(this, key, value, opts);
};

firebase.firestore.Query.prototype.observer = function() {
  return new flarebase.store.CollectionObserver(this);
};

export default flarebase;
