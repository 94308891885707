const riot = require('riot');

riot.tag2('module-rate-changes', '<div class="w-full box-shadow-primary" if="{items.length}"> <div class="bg-cell_title py8 f fh rounded-top-8 border-bottom"> <div class="w-full text-center fs12 text-base_pale" each="{item in items}">{item.label}</div> </div> <div class="bg-white py10 f fh rounded-bottom-8"> <div class="w-full" each="{item in items}"> <div class="f fh text-{getNumber(item) &lt; 0 ? \'minus\' : \'plus\'}"> <div class="h-full fs10 mr4 icon-{getNumber(item) &lt; 0 ? \'triangle_down\' : \'triangle_up\'}"></div> <div>{app.utils.localizeFixed(Math.abs(getNumber(item)))}%</div> </div> </div> </div> </div>', '', '', function(opts) {
    this.preload = async({req, res}) => {
      var selectedCurrency = app.utils.selectedCurrency.getId();
      var items = [
        {id: '1h', label: '1時間'},
        {id: '24h', label: '24時間'},
        {id: '7d', label: '7日間'},
        {id: '30d', label: '30日間'},
      ];
      return {
        selectedCurrency,
        items
      };
    };

    this.on('update', () => {
      this.selectedCurrency = app.utils.selectedCurrency.getId();
    });

    this.getNumber = (item) => {
      var key = `${this.selectedCurrency.toLowerCase()}_percent_change_${item.id}`;
      return this.opts.currency.data[key].toFixed(2);
    };
});