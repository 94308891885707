import dayjs from "dayjs";
import flarebase from "./flarebase";


var store = {
  // 日次マーケット情報取得
  daily_information: {
    get: async () => {
      return await flarebase.store.get('generals', 'cryptocurrency_daily_information', {cache: false});
    },
  },
  exchanges: {
    index: async({limit, last}) => {
      var ref = flarebase.store.collection('exchanges').orderBy("rank", "desc").orderBy('score', 'desc').limit(limit);
      if (limit) {
        ref = ref.limit(limit);
      }
      if (last) {
        ref = ref.startAfter(last);
      }
      var items = await flarebase.store.getCollection(ref);
      return items;
    },
    getBySlug: async(slug) => {
      var ref = flarebase.store.collection('exchanges').where('slug', '==', slug);
      var items = await flarebase.store.getCollection(ref);
      return items[0];
    },
    getById: async(id) => {
      var item = await flarebase.store.get('exchanges', id);
      return item;
    },
  },
  exchange_reviews: {
    index: async({key="sort_num", order="asc", limit=3, last}) => {
      var ref = flarebase.store.collection('exchange_reviews').where('disabled', '==', false).orderBy(key, order).limit(limit);
      if (last) {
        ref = ref.startAfter(last);
      }
      var items = await flarebase.store.getCollection(ref);
      return items;
    },
    // デフォルトでは、score降順
    indexBySlug: async({slug, index="score", order="desc", limit=3, last} ) => {
      var ref = firebase.firestore().collection('exchange_reviews').where('exchange_id', '==', slug).where('disabled', '==', false).orderBy(index, order).limit(limit);
      if (last) {
        ref = ref.startAfter(last);
      }
      var items = await flarebase.store.getCollection(ref);
      return items;
    }
  },
  exchange_pairs: {
    index: async() => {
      var ref = flarebase.store.collection('exchange_pairs');
      return await flarebase.store.getCollection(ref);
    },
    // exchange slugと一致するものを取得
    indexByExchange: async(slug) => {
      var ref = flarebase.store.collection('exchange_pairs').where('exchange_id', '==', slug).orderBy('btc_volume24h', 'desc');
      return await flarebase.store.getCollection(ref);
    },
    // 通貨ペア元と一致するものを取得
    indexByFromCurrencyId: async({currency_id, limit=20, last, toSymbol, tradeCategory}) => {
      var ref = flarebase.store.collection('exchange_pairs').where('from_currency_id', '==', currency_id).limit(limit);
      if (toSymbol) {
        ref = ref.where("to_symbol", "==", toSymbol);
      }
      if (tradeCategory) {
        ref = ref.where("trade", "==", tradeCategory);
      }
      if (last) {
        ref = ref.startAfter(last);
      }
      return await flarebase.store.getCollection(ref);
    },
  },
  exchange_merits: {
    // exchange_idと一致する一覧を取得
    indexByExchange: async(slug, limit) => {
      var ref = flarebase.store.collection('exchange_merits').where('exchange_id', '==', slug).where('disabled', '==', false).orderBy('sort_num', 'asc');
      if (limit) {
        ref = ref.limit(limit);
      }
      return await flarebase.store.getCollection(ref);
    },
  },
  exchange_feeds: {
    // exchange_idと一致する一覧を取得
    indexByExchange: async({slug, limit, last}) => {
      var ref = flarebase.store.collection('exchange_feeds').where('exchange_id', '==', slug).where('disabled', '==', false).orderBy('published_at', 'desc');
      if (limit) {
        ref = ref.limit(limit);
      }
      if (last) {
        ref = ref.startAfter(last);
      }
      return await flarebase.store.getCollection(ref);
    },
  },
  currencies: {
    getAll: async(kind="jpy_market_cap", order="desc") => {
      var ref = flarebase.store.collection('currencies').orderBy(kind, order);
      var items = await flarebase.store.getCollection(ref);
      return items.filter(item => item.data.kind === "crypto");
    },
    cryptoIndex: async({kind, range, order, last, tag, limit = app.utils.currencyTable.limit}) => {
      var ref = flarebase.store.collection('currencies');
      // 範囲指定があるときと無い時で場合わけ
      if (tag) {
        ref = ref.where('tags', 'array-contains', tag);
      }
      if (range) {
        // TODO: ph2 order 切替実装
        var ref = ref.where(range.type, '>=', +range.min).where(range.type, '<=', +range.max).orderBy(range.type, 'desc').limit(limit);
      } else {
        ref = ref.orderBy(kind, order).limit(limit);
      }
      if (last) {
        ref = ref.startAfter(last);
      }
      var items = await flarebase.store.getCollection(ref);
      return items.filter(item => item.data.kind === "crypto");
    },
    // slugを使って個別通貨をfetch
    getBySlug: async(slug) => {
      var ref = flarebase.store.collection('currencies').where('slug', '==', slug);
      var items = await flarebase.store.getCollection(ref);
      return items[0];
    },
  },
  cryptocurrency_feeds: {
    // currencyのsymbolを含む一覧を取得
    indexBySymbol: async({symbol, limit, last}) => {
      var ref = flarebase.store.collection('cryptocurrency_feeds').where('tags', 'array-contains', symbol).where('disabled', '==', false).orderBy('published_at', 'desc');
      if (limit) {
        ref = ref.limit(limit);
      }
      if (last) {
        ref = ref.startAfter(last);
      }
      return await flarebase.store.getCollection(ref);
    },
  },
  columns: {
    // wordpressからコラムを取得
    get: async({id, limit=9}) => {
      // 指定したslugでタグのid(数字)取得
      var res = await fetch(`https://kasobu.com/wp-json/wp/v2/tags?slug=${id}`);
      var tags = await res.json();
      
      // 上記idをもとにコラム記事取得
      if (tags[0]) {
        var res = await fetch(`https://kasobu.com/wp-json/wp/v2/posts?tags=${tags[0].id}&per_page=${limit}&_embed`);
        var posts = await res.json();
        return posts;
      }
    },
  },
  pairs: {
    // ローソク足データ取得用
    index: async({pair, term, limit, last, start, end}) => {
      var ref = flarebase.store.collection(`pairs/${pair}/candlestick_1${term}`).orderBy('open_at', 'desc');
      if (limit) {
        ref = ref.limit(limit);
      }
      if (start) {
        ref = ref.where("open_at", ">=", start);
      }
      if (end) {
        ref = ref.where("open_at", "<=", end);
      }
      if (last) {
        ref = ref.startAfter(last);
      }
      var items = await flarebase.store.getCollection(ref);
      return items;
    },
  },
  // mailchimpへのリスト登録
  registerEmail: (data) => {
    return new Promise((resolve, reject) => {
      $.post(
        '/api/mail/subscribe',
        data
      ).done((res) => {
        resolve(res);
      }).fail((error) => {
        reject(error);
      });
    });
  },
  news: {
    // ヘッドライン(全ニュースから最新のものを取得)
    getRecent: async(limit=4) => {
      var res = await fetch(`https://kasobu.com/wp-json/wp/v2/news?per_page=${limit}&_embed`);
      var items = await res.json();
      return items;
    },
    // view数多い順で取得
    getRanking: async(limit=10) => {
      var res = await fetch(`https://kasobu.com/wp-json/wp/v2/news?order_by=views&per_page=${limit}&_embed&after=${dayjs().subtract(1, 'week').format("YYYY-MM-DDTHH:mm:ss")}`);
      var items = await res.json();
      return items;
    },
    // wordpressからnewsを取得
    getByTag: async({id, limit = 15}) => {
      // 指定したslugでタグのid(数字)取得
      var res = await fetch(`https://kasobu.com/wp-json/wp/v2/tags?slug=${id}`);
      var tags = await res.json();

      // 上記idをもとにnews記事取得
      var tag = tags[0];
      if (tag) {
        var res = await fetch(`https://kasobu.com/wp-json/wp/v2/news?tags=${tag.id}&per_page=${limit}&_embed`);
        var posts = await res.json();
        return posts;
      }
    },
    // wordpress newsをcategoryで取得
    getByCategory: async({id, limit = 15}) => {
      // 指定したslugでタグのid(数字)取得
      var res = await fetch(`https://kasobu.com/wp-json/wp/v2/categories?slug=${id}`);
      var tags = await res.json();

      // 上記idをもとにnews記事取得
      var tag = tags[0];
      if (tag) {
        var res = await fetch(`https://kasobu.com/wp-json/wp/v2/news?tags=${tags.id}&per_page=${limit}&_embed`);
        var posts = await res.json();
        return posts;
      }
    },
  },
  tags: {
    index: async() => {
      var ref = flarebase.store.collection('tags').where('disabled', '==', false);
      return await flarebase.store.getCollection(ref);
    },
    categoryIndex: async() => {
      var ref = flarebase.store.collection('tags').where('disabled', '==', false);
      var items = await flarebase.store.getCollection(ref);
      items = items.filter(item => !!item.data.is_category);
      return items;
    },
    get: async(id) => {
      var item =  await flarebase.store.get('tags', id);
      return item;
    },
  },
}

export default store;