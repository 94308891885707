const riot = require('riot');

riot.tag2('module-exchange-reviews', '<div class="bg-white rounded-8 pt18 pb16 px16"> <h2 class="fs18 bold lh15 mb11">{opts.title} の口コミ・評判・レビュー</h2> <div class="f mb16"> <div class="mr12 text-base_pale bold">ユーザー評価</div> <div class="mr4" data-is="atom-stars" score="{opts.exchange.data.user_score}"></div> <div class="bold mr4">{opts.exchange.data.user_score.toFixed(1)}</div> <div>({opts.exchange.data.review_count})</div> </div> <div class="mb16" each="{item in app.utils.exchangeSingle.getShowItems(exchange_reviews, limit, opts.isdetail)}"> <div data-is="item-exchange-review" item="{item}" exchange="{opts.exchange}"></div> </div> <div class="f fh" if="{opts.isdetail}"> <button class="f fm cursor-pointer p8 text-primary" onclick="{onReadMore}" if="{exchange_reviews.length &amp;&amp; hasMore}"> <div class="fs12 mr9">さらにもっと見る</div><i class="icon-arrow_down fs5"></i> </button> </div> <div class="f fr text-primary pb8" if="{!opts.isdetail}"><a class="f fm" href="/exchange/{opts.exchange.data.slug}/review"> <div class="fs12 mr8">{opts.exchange.data.name_en} ({opts.exchange.data.name_ja}) の口コミをもっと見る</div><i class="icon-arrow_right fs8"></i></a></div> </div>', '', '', function(opts) {

    this.preload = async({req,res}) => {
      this.limit = 3;
      var exchange_reviews = await app.store.exchange_reviews.indexBySlug({slug: req.params.id});

      this.hasMore = exchange_reviews.length && exchange_reviews.length === this.limit;
      return {
        exchange_reviews,
      };
    };

    this.onReadMore = async(e) => {
      e.preventDefault();
      const limit = 10;
      var last = this.exchange_reviews[this.exchange_reviews.length - 1].doc;
      var items = await app.store.exchange_reviews.indexBySlug({slug: opts.exchange.data.slug, limit, last});
      this.hasMore = items.length && items.length === limit;
      this.exchange_reviews.push(...items);
      this.update();
    };
});