const riot = require('riot');

riot.tag2('page-cryptocurrencies-chart', '<div class="bg-white container m32"> <div class="p32"> <h1 class="fs24 mb8">{currency.id}</h1> <div class="fs32 bold mb10 s-fs24" data-is="atom-amount-display" riot-value="{app.utils.localizeFixed(tags[\'module-chart\'].graph.value)}"></div> <time>{app.utils.formatDate.days(tags[\'module-chart\'].chart.date)}</time> </div> <div ref="chart" data-is="module-chart"></div> </div>', '', '', function(opts) {
    this.preload = async({req, res}) => {
      var currency = await firebase.firestore().doc(`/currencies/${req.params.id}`).get();
      var unit = app.utils.selectedCurrency.getId();

      if (unit === 'BTC') {
        if (currency.id === 'bitcoin') {
          unit = 'jpy';
        } else {
          unit = 'bitcoin';
        }
      }

      return {
        currency: currency,
        pair: currency.id + '_' + unit.toLowerCase(),
      };
    };

    this.on('mount', () => {
      this.tags['module-chart'].on('chartUpdate', () => {
        this.update();
      });
    });

    this.on('show', () => {
      this.refs.chart.setPair(this.pair);
    });
});