const riot = require('riot');

riot.tag2('module-daily-info', '<div class="bg-white px24 py16 s-p16 rounded-8 box-shadow-main"> <div class="mb16 w-full f fbw fm"> <h2 class="fs18 bold">Daily Info</h2> <div class="fs12 text-base_pale">{app.utils.formatDate.minutes(opts.item.data.updated_at)}更新</div> </div> <div class="f s-flex-column"> <div class="mb10 mr32" each="{item in items.slice(0, 4)}"><span class="mr8">{item.label}</span><span class="text-base_pale">{\'¥\' : item.type === \'amount\'}{app.utils.localize(opts.item.data[item.id])}</span></div> </div> <div class="f s-flex-column"> <div class="mb10 mr32 s-mb4" each="{item in items.slice(4, 7)}"><span class="mr8">{item.label}</span><span class="text-base_pale">{opts.item.data[item.id].toFixed(2)}{\'%\' : item.type === \'percentage\'}</span></div> </div> </div>', '', '', function(opts) {
    this.items = [
      {id: 'asset_count', label: '仮想通貨数'},
      {id: 'pair_count', label: '通貨ペア数'},
      {id: 'market_cap', label: '時価総額', type: 'amount'},
      {id: 'volume_24h', label: '24時間出来高', type: 'amount'},
      {id: 'btc_dominance', label: 'BTCドミナンス', type: 'percentage'},
      {id: 'eth_dominance', label: 'ETHドミナンス', type: 'percentage'},
      {id: 'usdt_dominance', label: 'USDTドミナンス', type: 'percentage'},
    ];
});