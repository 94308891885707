const riot = require('riot');

riot.tag2('page-error', '<div class="f fclm h-full"> <header class="mb24" data-is="module-header"></header> <div class="h-full px12 mb72 s-mb48"> <div class="container f fh fclm h-full bg-white box-shadow-primary rounded-8"> <div class="w-full max-w480 mb48 s-px16"><img class="block object-fit-contain mb24" if="{errorMessage.statusCode &amp;&amp; errorMessage.statusCode != 200}" riot-src="{errorMessage.img}" loading="lazy"> <div class="fs16 text-center mb24">{errorMessage.description}</div> <div class="bold h52" data-is="atom-button-action" text="TOPへ" link="/"></div> </div> </div> </div> <footer class="mta" data-is="module-footer"></footer> </div>', '', '', function(opts) {
    this.preload = ({req, res}) => {
      let statusCode = res.statusCode;
      if ((!statusCode || statusCode == 200) && res.error) {
        statusCode = res.error.statusCode;
      }
      var errorMessage = {
        404: {
          img: '/images/error/404.svg',
          description: 'ページが見つかりませんでした。',
          statusCode,
        },
      }[statusCode];

      if (!errorMessage) {
        errorMessage = {
          description: 'エラーが発生しました。',
          statusCode,
        };
      }
      res.status(statusCode || 500);
      return {
        errorMessage,
      };
    };

    this.head = () => {
      return {
        title: `${this.errorMessage.description} | ${spat.config.head.ogp.site_name}`,
      };
    };
});